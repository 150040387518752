export default {
  "period.from": "Periods no",
  "period.to": "Periods līdz",
  "delete.not.allowed": "Lietotājam nav atļauts dzēst",
  back: "Atpakaļ",
  upload: "Augšupielādēt",
  "edit.contract": "Labot līgumu",
  "message.no": "Nē",
  "message.yes": "Jā",
  "message.year": "gads",
  "login.page.login": "Pieslēgties",
  "login.page.login.text": "Pieslēdzieties savā kontā",
  "login.page.placeholder.email": "E-pasts",
  "login.page.placeholder.password": "Parole",
  "login.page.login.button": "Pieslēgties",
  "route.administration": "Administrācija",
  "route.administration.users": "Lietotāju administrācija",
  "route.administration.organizations": "Organizāciju administrācija",
  "route.administration.platform.settings": "Platformas iestatījumi",
  "route.suppliers.report": "Atskaites piegādātāji",
  "route.supplier.partner.report": "Atskaites piegādātāji un partneri",
  "route.partners.report": "Atskaites partneri",
  "route.losses.report": "Atskaites nožuvumi",
  "route.suppliers": "Piegādātāji",
  "route.supplier.card": "Organizācijas kartiņa",
  "route.suppliers.discounts.total": "Piegādātāju apjoma atlaides kopā",
  "route.partners": "Partneri",
  "route.partners.discounts.total": "Partneru apjoma atlaides kopā",
  "route.turnover.overview": "Pārskati",
  "route.turnover.submit": "Pārskatu iesniegšana",
  "route.turnover.summary": "Pārskatu kopsavilkums",
  "route.turnover.archive": "Pārskatu arhīvs",
  "route.contracts.supplier": "Piegādātāju līgumi",
  "route.contracts.partner": "Partneru līgumi",
  "route.panel": "Vadības panelis",
  "panel.submitted": "Iesniegtie pārskati",
  "panel.missing": "Nenodotie pārskati",
  "panel.extracted": "Izrakstītie pārskati",
  "admin.org.title": "Organizāciju administrācija",
  "admin.org.button.add": "Pievienot",
  "admin.org.button.filter": "Filtrēt",
  "admin.org.create.title": "Jauna organizācija",
  "admin.org.form.name": "Organizācijas nosaukums",
  "admin.org.form.address": "Adrese",
  "admin.org.form.city": "Pilsēta",
  "admin.org.form.resident": "Rezidents",
  "admin.org.form.reg.nr": "Reģistrācijas numurs",
  "admin.org.form.pvn.reg.nr": "PVN Reģistrācijas numurs",
  "admin.org.form.region.code": "Valsts/regiona kods",
  "admin.org.form.organization.type": "Organizācijas tips",
  "admin.org.form.save": "Saglabāt",
  "admin.org.form.back": "Atpakaļ",
  "admin.user.list.title": "Lietotāju saraksts",
  "admin.user.list.button.notify.toggle": "Atgādinājumu nosūtīšana",
  "admin.user.list.button.select.all": "Izvēlēties visus",
  "admin.user.list.button.clear.all": "Notīrīt visus",
  "admin.user.list.button.notify": "Nosūtīt atgādinājumu",
  "admin.user.check.disabled": 'Lietotāja organizācija nav ar tipu "Piegādātājs"',
  "admin.user.list.button.add": "Pievienot",
  "admin.user.list.header.name": "Vārds",
  "admin.user.list.header.email": "E-pasts",
  "admin.user.list.header.organization.type": "Organizācijas tips",
  "admin.user.list.header.organization": "Organizācija",
  "admin.user.form.create.title": "Jauns lietotājs",
  "admin.user.form.update.title": "Labot lietotāju",
  "admin.user.form.firstName": "Vārds",
  "admin.user.form.lastName": "Uzvārds",
  "admin.user.form.email": "E-pasts",
  "admin.user.form.phone.number": "Tālruņa numurs",
  "admin.user.form.organizations": "Organizācija",
  "admin.user.form.default.organizations": "Noklusējuma organizācija",
  "admin.user.form.organization.type": "Organizācijas tips",
  "admin.user.form.save": "Saglabāt",
  "admin.user.form.back": "Atcelt",
  "admin.user.form.main.user": "Galvenais lietotājs",
  "admin.user.form.super.user": "Super lietotājs",
  "admin.user.form.password": "Parole",
  "admin.user.form.password.repeat": "Atkārtot paroli",
  "admin.user.form.old.password": "Vecā parole",
  "category.form.create": "Pievienot",
  "category.form.update": "Labot",
  "category.form.cancel": "Atcelt",
  "category.form.description.add": "Jaunas kategorijas izveide",
  "category.form.description.edit": "Kategorijas labošāna",
  "user.forgot.title": "Aizmirsu paroli",
  "user.forgot.submit": "Sūtīt",
  "user.forgot.back": "Atpakaļ",
  "user.register.title": "Lietotāja datu iestatīšana",
  "user.settings": "Lietotāja uzstādījumi",
  "validation.password.insecure":
    "Parolei jāsatur vismaz 8 simbolus, vienu mazo burtu, vienu lielo burtu un vienu ciparu vai vienu speciālo simbolu",
  "validation.required": "Obligāts lauks",
  "validation.email": "Nepareizs e-pasts",
  "validation.phone.number": "Nepareizs tālruņa numurs",
  "validation.password.not.match": "Paroles nesakrīt",
  "validation.periods.required": "Abiem perioda laukiem jābūt aizpildītiem",
  "validation.category.exists": "Šāda kategorija jau eksistē",
  "validation.platform.duedate.invalid":
    "Iesniegšanas datumam jābūt derīgam skaitlim",
  "validation.period.from.smaller.than.to":
    "Periodam no jābūt vienādam vai pirms perioda līdz",
  "validation.period.to.larger.than.from":
    "Periodam līdz jābūt vienādam vai pēc perioda no",
  "valdation.country.code.length": "Koda garumam jābūt divus simbolus garam",
  "error.403": "Lietotājam lapai piekļuve liegta",
  "error.404": "Lapa nav atrasta",
  "toastr.supplier.upload.success":
    "Piegādātāja līgumi veiksmīgi augšupielādēti",
  "toastr.organization.created": "Organizācija izveidota!",
  "toastr.organization.updated": "Organizācija labota!",
  "toastr.organization.deleted": "Organizācija dzēsta!",
  "toastr.user.created": "Lietotājs izveidots!",
  "toastr.user.error": "Neizdevās izveidot lietotāju!",
  "toastr.user.updated": "Lietotājs labots!",
  "toastr.user.failure": "Neizdevās labot lietotāju!",
  "toastr.user.email.error": "Nevar mainīt lietotāja ēpastu!",
  "toastr.user.organization.field.empty": "Lūdzu ievadiet organizāciju!",
  "toastr.user.deleted": "Lietotājs dzēsts!",
  "toastr.user.regtoken.reset.success": "Lietotājam ziņa aizsūtīta uz epastu",
  "toastr.user.regtoken.reset.failure":
    "Kļūda veicot paroles atjaunināšanas procedūru",
  "toastr.user.register.success": "Reģistrācija veiksmīga",
  "toastr.user.register.error": "Reģistrācijas kļūda",
  "toastr.user.organization.block.success": "Lietotājs bloķēts",
  "toastr.user.organization.unblock.success": "Lietotājs atbloķēts",
  "toastr.suppler.partners.update.success": "Apjoma atlaides iesniegtas",
  "toastr.suppler.partners.update.error": "Apgrizījuma iesniegšanas kļūda",
  "toastr.supplier.contract.create.success": "Līgums pievienots veiksmīgi",
  "toastr.supplier.contract.create.update": "Līgums labots veiksmīgi",
  "toastr.supplier.contract.create.error": "Līguma pievienošanas kļūda",
  "toastr.partner.contract.create.success": "Līgums pievienots veiksmīgi",
  "toastr.partner.contract.create.update": "Līgums labots veiksmīgi",
  "toastr.partner.contract.create.error": "Līguma pievienošanas kļūda",
  "toastr.supplier.contract.delete.success": "Piegādātāja līgums dzēsts!",
  "toastr.platform.update.success": "Platformas iestatījumi mainīti!",
  "toastr.category.created": "Kategorija izveidota!",
  "toastr.category.updated": "Kategorija labota!",
  "toastr.partner.contract.delete.success": "Partnera līgums dzēsts!",
  "toastr.upload.failure": "Augšupielādes kļūda",
  "toastr.turnover.upload.file.retrieved": "Importa atlaižu fails saņemts",
  "toastr.turnover.upload.turnovers.updated": "Apjoma atlaižu dati importēti",
  "toastr.turnover.upload.turnovers.recalculated":
    "Apjoma atlaižu vērtības aprēķinātas",
  "toastr.turnover.upload.turnovers.success":
    "Apjoma atlaižu imports ir veiksmīgs",
  "toastr.turnover.upload.turnovers.failure": "Apjoma atlaižu importa kļūda",
  "toastr.password.change.required": "Jūsu lietotājam obligāti jāmaina parole",
  "toastr.password.change.success": "Lietotāja parole veiksmīgi mainīta",
  "toastr.password.change.failure": "Lietotāja parole maiņas kļūda",
  "api.captcha.invalid": "CAPTCHA izpilde neveiksmīga, lūdzu pārlādējiet lapu",
  "api.error.recipients.not.specified": "Organizācijai nav epastu saņēmēju",
  "api.error.edit.extracted": "Jau iesniegtu apjoma atlaidi nav atļauts labot",
  "api.error.supplier.contract.upload.invalid.data":
    "Līguma dati ievadīti nepareizi.",
  "api.error.user.not.exist": "Lietotājs neeksistē!",
  "api.error.user.notify.empty": "Lūdzu norādiet vismaz vienu lietotāju atgādinājuma sūtīšanai!",
  "api.error.validation.not.empty": "Lauks nedrīkst būt tukšs",
  "api.error.validation.unique.registrationNr":
    "Rēgistrācijas numurs jau aizņemts",
  "api.error.validation.unique.email": "E-pastam jābūt unikālam",
  "api.error.validation.email.invalid": "E-pasts netika atrasts",
  "api.error": "Pieprasījuma kļūda!",
  "api.error.user.blocked": "Lietotājs ir bloķēts!",
  "api.error.organization.blocked": "Organizācija ir bloķēta!",
  "api.error.validation.unique.partner.contract":
    "Var pastāvēt tikai viens partnera līgums priekš viena tīkla/tipa",
  "api.error.organization.exists": "Šāda organizācija jau eksistē",
  "api.date.range.error": "Datums no nevar būt lielāks par datums līdz",
  "api.date.before.error": "Līgums nevar tikt izveidots pagātnē",
  "api.partner.contract.exists.error":
    "Partnera līgums konfliktē ar eksistējošiem līgumiem",
  "api.supplier.contract.exists.error": "Šāds līgums jau eksistē",
  "api.supplier.contract.number.required": "Ievadiet līguma numuru",
  "api.supplier.category.id.required": "Ievadiet produkta kategoriju",
  "api.supplier.responsible.users.required": "Ievadiet atbildīgos IM",
  "api.supplier.contract.contract.order.required":
    "Piegādātāju līgums konfliktē ar eksistējošiem līgumiem",
  "api.supplier.contract.contract.order.exists":
    "Šāds apakšlīguma numurs jau eksistē",
  "api.error.old.password.does.not.match": "Vecā parole ievadīta nepareizi",
  "api.auth.error": "Autentifikācijas kļūda!",
  "api.auth.timeout":
    "Lietotājs pārāk daudz reizes ir nepareizi ievadījis paroli. Lūdzu mēģiniet pēc 15 minūtēm!",
  "api.error.new.password.invalid": "Paroles nesakrīt",
  "api.error.turnover.due.date":
    "Par šo periodu vēl nav iespējams iesniegt apjoma atlaides",
  "api.error.turnover.periods.should.be.equal": "Izvēlētais periods nesakrīt ar failā norādīto perioda tipu",
  "api.error.turnover.contract.expired": "Kāds no importā norādītajiem līgumiem ir ar iztecējušu termiņu",
  "api.error.turnover.contract.archived": "Kāds no importā norādītajiem līgumiem ir arhivēts",
  "api.error.turnover.contract.specific.expired": "Līgums ir ar iztecējušu termiņu",
  "api.error.turnover.contract.specific.archived": "Līgums ir arhivēts",
  "api.error.old.password.equal.to.new":
    "Jaunā parole nedrīkst sakrist ar veco",
  "modal.organization.block.title": "Bloķēt organizāciju?",
  "modal.organization.block.text":
    "Bloķētas organizācijas lietotāji nevarēs veikt darbu sistēmā",
  "modal.organization.block.accept": "Bloķēt",
  "modal.organization.block.cancel": "Atcelt",
  "modal.organization.unblock.title": "Atbloķēt organizāciju?",
  "modal.organization.unblock.text":
    "Atbloķētas organizācijas lietotājiem būs atļauja veikt darbu sistēmā",
  "modal.organization.unblock.accept": "Atbloķēt",
  "modal.organization.unblock.cancel": "Atcelt",
  "modal.user.block.title": "Bloķēt lietotāju",
  "modal.user.block.text": "Bloķēts lietotājs nevarēs lietot sistēmu",
  "modal.user.block.accept": "Bloķēt",
  "modal.user.block.cancel": "Atcelt",
  "modal.user.unblock.title": "Atbloķēt lietotāju?",
  "modal.user.unblock.text": "Atbloķēts lietotājs atkal varēs lietot sistēmu",
  "modal.user.unblock.accept": "Atbloķēt",
  "modal.user.unblock.cancel": "Atcelt",
  "modal.reg.token.issue.title": "Atjaunot paroli",
  "modal.reg.token.issue.text":
    "Lietotājam tiks izsūtīta ziņa uz e-pastu, kuru varēs izmantot, lai par jaunu iestatīt paroli",
  "modal.reg.token.issue.accept": "Izsūtit",
  "modal.reg.token.issue.cancel": "Atcelt",
  "unfinished.overview.title": "Nenodota atskaite",
  "modal.partner.contracts.delete.title": "Dzēst līgumu",
  "modal.partner.contracts.delete.text": "Vai tiešām vēlaties dzēst līgumu?",
  "modal.partner.contracts.delete.accept": "Dzēst",
  "modal.partner.contracts.delete.cancel": "Atcelt",
  "modal.supplier.contracts.delete.title": "Dzēst līgumu",
  "modal.supplier.contracts.delete.text": "Vai tiešām vēlaties dzēst līgumu?",
  "modal.supplier.contracts.delete.accept": "Dzēst",
  "modal.supplier.contracts.delete.cancel": "Atcelt",
  "modal.supplier.contracts.send.email": "Nosūtīt e-pastu ar atgādinājumu",
  "modal.categories.title": "Kategoriju pārvaldība",
  "modal.email.send.organization.members":
    "E-pasts tiks nosūtīts visiem reģistrētajiem organizācijas lietotājiem",
  "modal.send.email.missing.overview":
    "Nosūtīt e-pastu ar atgādinājumu par nenodotiem pārskatiem?",
  "modal.email.send.user":
    "E-pasts tiks nosūtīts izvēlētajiem lietotājiem",
  "modal.user.delete.title": "Dzēst lietotāju?",
  "modal.user.delete.text": "Vai tiešām vēlaties dzēst lietotāju?",
  "modal.user.delete.accept": "Dzēst",
  "modal.user.delete.cancel": "Atcelt",
  "modal.send.selected.email.missing.overview":
    "Nosūtīt e-pastus ar atgādinājumu par nenodotiem pārskatiem?",
  "modal.email.send.selected.organization.members":
    "E-pasts tiks nosūtīts visiem izvēlēto organizāciju reģistrētajiem lietotājiem",
  "modal.supplier.contracts.selected.send.email":
    "Nosūtīt e-pastus ar atgādinājumu",
  "modal.supplier.contracts.selected.delete.cancel": "Atcelt",
  "turnover.submit.title": "Apjoma atlaižu iesniegšana",
  "turnover.summary.title": "Apjoma atlaižu pārskatu kopsavilkums",
  "classifiers.Q": "ceturksnis",
  "classifiers.M": "mēnesis",
  "classifiers.F": "Franšīzes",
  "classifiers.N": "Navīzijas",
  "classifiers.M1": "Janvāris",
  "classifiers.M2": "Februāris",
  "classifiers.M3": "Marts",
  "classifiers.M4": "Aprīlis",
  "classifiers.M5": "Maijs",
  "classifiers.M6": "Jūnijs",
  "classifiers.M7": "Jūlijs",
  "classifiers.M8": "Augusts",
  "classifiers.M9": "Septembris",
  "classifiers.M10": "Oktobris",
  "classifiers.M11": "Novembris",
  "classifiers.M12": "Decembris",
  "classifiers.Q1": "1. ceturksnis",
  "classifiers.Q2": "2. ceturksnis",
  "classifiers.Q3": "3. ceturksnis",
  "classifiers.Q4": "4. ceturksnis",
  "classifiers.network.E": "Elvi",
  "classifiers.network.A": "Alkoutlet",
  "classifiers.org.type.S": "Piegādātājs",
  "classifiers.org.type.P": "Partneris",
  "classifiers.org.type.ELVI": "ELVI",
  "classifiers.search.suppliercontracts": "Piegādātāju līgumi",
  "classifiers.search.partnercontracts": "Partneru līgumi",
  "classifiers.search.organizations": "Organizācijas",
  "select.no.contract": "Nav līgumu",
  "send.email": "Nosūtīt atgādinājuma e-pastu",
  "send.email.disabled": "Līguma organizāciai nav piesaistītu lietotāju",
  "notify.user.disabled.no.missing": "Lietotāja organizācijām nav neiesniegtu pārskatu",
  "notify.user.disabled.not.supplier": "Lietotājs nav pārvadātājs",
  "notify.user": "Nosūtīt atgādinājumu lietotājam",
  "supplier.contract.list.header.registration.nr": "PVN Reģistrācijas Nr.",
  "supplier.contract.list.header.supplier.name": "Piegādātājs",
  "supplier.contract.list.header.contract.nr": "Mārketinga līguma Nr.",
  "supplier.contract.list.header.category": "Preču kategorija",
  "supplier.contract.list.header.description": "Preču apraksts",
  "supplier.contract.list.header.period": "Periods",
  "supplier.contract.list.header.trepe": "Apjoma atlaižu trepe",
  "supplier.contract.list.header.turnover": "Apgrozījums (bez PVN)",
  "supplier.contract.list.header.volume.discount.percent": "Apjoma atlaide %",
  "supplier.contract.list.header.volume.discount.value":
    "Apjoma atlaide (bez PVN)",
  "supplier.contract.list.header.losses": "Nožuvuma procents",
  "supplier.contract.list.header.submit.date": "Iesn. datums",
  "supplier.contract.list.header.approved": "Pieņemts",
  "supplier.contract.list.header.date.from": "Līg dat. no",
  "supplier.contract.list.header.date.to": "Līg. dat. līdz",
  "supplier.contract.list.header.related.contracts": "Saistītie līgumi",
  "supplier.contract.list.header.discount": "Apjoma atlaižu trepe",
  "supplier.contract.list.header.responsible.im": "Atbildīgie IM",
  "supplier.contract.list.header.organization": "Organizācija",
  "supplier.contract.list.upload": "Augšupielādēt līgumu",
  "supplier.contract.list.period": "Periods",
  "supplier.contract.list.partners.submit": "Iesniegt",
  "supplier.contract.list.partners.cancel": "Atcelt",
  "supplier.contract.list.partners.contract.nr": "Līguma Nr",
  "supplier.contract.list.partners.category": "Preču kategorija",
  "supplier.contract.list.partners.description": "Preču apraksts",
  "supplier.contract.list.partners.summary.total": "Kopā",
  "supplier.contract.list.partners.submit.empty": "Iesniegt tukšu apgrozījumu",
  "supplier.contract.list.partners.submit.empty.aaa":
    "Iesniegt tukšu apjoma atlaidi",
  "supplier.contract.list.partners.header.partner.name": "Partnera nosaukums",
  "supplier.contract.list.partners.header.elvi": "ELVI",
  "supplier.contract.list.partners.header.alko": "Alkoutlet",
  "supplier.contract.list.partners.related.turnover.sum":
    "Saistīto līgumu summa",
  "supplier.contract.card.form.contract.nr": "Līguma Nr",
  "supplier.contract.card.form.category": "Preču kategorija",
  "supplier.contract.card.form.description": "Preču apraksts",
  "supplier.contract.card.form.date.start": "Līguma datums no",
  "supplier.contract.card.form.date.end": "Līguma datums līdz",
  "supplier.contract.card.form.period": "Periodiskums",
  "supplier.contract.card.form.contract.has.relations": "Saistītie līgumi",
  "supplier.contract.card.form.contract.absolute.turnover":
    "Absolūtā apjomu atlaide",
  "supplier.contract.card.form.trepe": "Apjoma atlaide %",
  "supplier.contract.card.form.losses": "Nožuvuma procents %",
  "supplier.contract.card.form.trepe.add": "Pievienot jaunu trepi",
  "supplier.contract.card.form.trepe.remove": "Dzēst pēdējo trepi",
  "supplier.contract.card.form.responsible.users": "Atbildīgie IM",
  "supplier.contract.card.form.responsible.user": "Atbildīgais IM",
  "contract.card": "Līguma kartiņa",
  "partner.contract.list.header.organization": "Organizācija",
  "partner.contract.list.header.contract.nr": "Mārketinga līguma Nr.",
  "partner.contract.list.header.contract.type": "Līguma veids",
  "partner.contract.list.header.coefficient": "Dalījuma koeficients",
  "partner.contract.list.header.network.type": "Tīkls",
  "partner.contract.list.header.date.from": "Līguma datums no:",
  "partner.contract.list.header.date.to": "Līguma datums līdz:",
  "partner.contract.list": "Līgumu saraksts",
  "partner.contract.list.add": "Pievienot jaunu līgumu",
  "partner.contract.card.form.contract.nr": "Līguma Nr",
  "partner.contract.card.form.contract.type": "Līguma veids",
  "partner.contract.card.form.network.type": "Tīkls",
  "partner.contract.card.form.date.start": "Līguma datums no",
  "partner.contract.card.form.date.end": "Līguma datums līdz",
  "partner.contract.card.form.coefficient": "Dalījuma koeficients",
  "partner.contract.card.form.submit": "Saglabāt",
  "partner.contract.card.form.cancel": "Atcelt",
  "partner.card.reg.nr": "Reģ. nr.",
  "partner.card.address": "Juridiskā adrese",
  "partner.card.contracts": "Līgumi",
  "partner.card.volume.discount": "Apjoma atlaides",
  "partner.discounts.total": "Partneru apjoma atlaides kopā",
  "partner.discounts": "Apjoma atlaides",
  "partner.discounts.partners": "Partneris",
  "partner.discounts.network": "Tīkls",
  "partner.discounts.period": "Periods",
  "partner.discounts.turnover.amount": "Apgrozījuma summa bez PVN",
  "partner.discounts.turnover.coef.amount": "Dalījums partneriem bez PVN",
  "partner.discounts.turnover.discount": "Apjoma atlaide bez PVN",
  "partner.discounts.turnover.losses": "Nožuvumi",
  "partner.discounts.turnover.extracted": "Pieņemts",
  "partner.discounts.suppliers": "Piegādātāji",
  "partner.discounts.registration.number": "PVN Reģistrācijas nr.",
  "partner.discounts.turnovers.total": "Kopā",
  "supplier.card.reg.nr": "Reģ. nr.",
  "supplier.card.address": "Juridiskā adrese",
  "supplier.card.contracts": "Līgumi",
  "supplier.card.turnovers.submit": "Iesniegt apjoma atlaides",
  "contract.archive": "Līgumu arhīvs",
  archive: "Arhivēt",
  unarchive: "Atarhivēt",
  edit: "Labot",
  delete: "Dzēst",
  actions: "Darbības",
  "period.year.month": "{year}.g {month}",
  "filter.period": "Periods",
  "filter.period.from": "Periods no",
  "filter.period.to": "Periods līdz",
  "filter.partners": "Partneris",
  "filter.suppliers": "Piegadātājs",
  "filter.network.type": "Tīkls",
  "filter.submit": "Meklēt",
  "filter.cancel": "Atcelt",
  "filter.extracted.status": "Pieņemšanas statuss",
  "filter.submit.date": "Iesniegšanas datums",
  "filter.submit.period": "Izrakstīšanas periods",
  "filter.product.category": "Preču kategorija",
  "filter.responsible.user": "Atbildīgais IM",
  "platform.settings.categories.manage": "Pārvaldīt kategorijas",
  "platform.settings.form.turnover.submit.due":
    "Pārskatu iesniegšanas datums līdz",
  "tooltip.submit.turnovers": "Iesniegt apjoma atlaides",
  "version.number": `Versija {version}`,
  "turnover.upload": "Augšupielādēt apjoma atlaides",
  "turnover.submit.period": "Izrakst. periods",
  uploading: "Augšupielādē",
  import: "Importēt",
  save: "Saglabāt",
  "user.change.password": "Paroles maiņa",
  "period.specific.checkbox": "Atlasīt pēc konkrētiem periodiem",
  "period.specific.add": "Pievienot periodu",
  "period.specific.delete": "Dzēst pēdējo periodu"
};
