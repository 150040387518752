import {
  USER_LIST_GET_START,
  USER_LIST_GET_SUCCESS,
  USER_LIST_GET_FAILURE,
  USER_GET_START,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  USER_UPDATE_FAILURE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_START,
  USER_CREATE_START,
  USER_CREATE_FAILURE,
  USER_CREATE_SUCCESS,
  USER_SUBMIT_GLOBAL_PROGRESS,
  USER_SUBMIT_GLOBAL_FINISH,
  USER_LIST_LOADING,
  USER_LIST_NOT_LOADING,
  USER_CURRENT_UPDATE_START,
  USER_CURRENT_UPDATE_SUCCESS,
  USER_CURRENT_UPDATE_FAILURE,
  USER_ITEM_RESET,
  USER_MISSING_CONTRACT_LIST_GET_START,
  USER_MISSING_CONTRACT_LIST_GET_SUCCESS,
  USER_MISSING_CONTRACT_LIST_GET_FAILURE,
  USER_MISSING_CONTRACT_LIST_CLEAR,
  USER_EMAIL_SEND_REMAINDER_START,
  USER_EMAIL_SEND_REMAINDER_FINISH
} from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  list: {
    data: [], // User data
    page: 1,
    count: 0,
  },
  item: {
    firstName: "",
    lastName: "",
    email: "",
    defaultOrganization: { value: null, label: "" },
    phoneNumber: "",
    organizations: [],
    mainUser: false,
    superuser: false,
    loading: false,
    elviOrganzationId: null,
  },
  userGlobalLoad: false,
  missingContracts: []
};

/* eslint-disable default-case */
/**
 * Redux reducer for users
 * @param state
 * @param action
 */
const userReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { payload, type } = action;
    switch (type) {
      case USER_LIST_GET_START: {
        draft.list.data = [];
        draft.list.count = 0;
        draft.list.loading = true;
        break;
      }

      case USER_LIST_GET_SUCCESS: {
        draft.list.data = payload.data.rows;
        draft.list.count = payload.data.count;
        draft.list.page = payload.page;
        draft.list.loading = false;
        break;
      }

      case USER_LIST_GET_FAILURE: {
        draft.list.data = [];
        draft.list.loading = false;
        draft.list.count = 0;
        break;
      }

      case USER_GET_FAILURE:
        draft.item.firstName = "";
        draft.item.lastName = "";
        draft.item.email = "";
        draft.item.phoneNumber = "";
        draft.item.loading = false;
        break;

      case USER_GET_START: {
        draft.item.firstName = "";
        draft.item.lastName = "";
        draft.item.email = "";
        draft.item.loading = true;
        break;
      }
      case USER_GET_SUCCESS:
        draft.item = { ...payload };
        draft.item.loading = false;
        break;

      case USER_UPDATE_START:
        draft.item.loading = true;
        break;

      case USER_UPDATE_SUCCESS:
        draft.item = { ...payload, loading: false };
        break;

      case USER_UPDATE_FAILURE:
        draft.item.loading = false;
        break;

      case USER_CREATE_START:
        draft.item.loading = true;
        break;

      case USER_CREATE_SUCCESS:
      case USER_CREATE_FAILURE:
        draft.item.loading = false;
        break;

      case USER_SUBMIT_GLOBAL_PROGRESS:
        draft.userGlobalLoad = true;
        break;

      case USER_SUBMIT_GLOBAL_FINISH:
        draft.userGlobalLoad = false;
        break;

      case USER_LIST_LOADING:
        draft.list.loading = true;
        break;

      case USER_LIST_NOT_LOADING:
        draft.list.loading = false;
        break;

      case USER_CURRENT_UPDATE_START:
        draft.item.loading = true;
        break;
      case USER_CURRENT_UPDATE_SUCCESS:
        draft.item.loading = false;
        draft.item = payload;
        break;
      case USER_CURRENT_UPDATE_FAILURE:
        draft.item.loading = false;
        break;
      case USER_ITEM_RESET:
        draft.item = defaultState.item;
        break;
      case USER_MISSING_CONTRACT_LIST_GET_START:
        draft.loading = true;
        break;
      case USER_MISSING_CONTRACT_LIST_GET_SUCCESS:
        draft.missingContracts = payload.contracts.rows;
        draft.loading = false;
        break;
      case USER_MISSING_CONTRACT_LIST_GET_FAILURE:
        draft.loading = false;
        break;
      case USER_MISSING_CONTRACT_LIST_CLEAR:
        draft.missingContracts = [];
        draft.loading = false;
        break;
      case USER_EMAIL_SEND_REMAINDER_START: {
        draft.loading = true;
        break;
      }
      case USER_EMAIL_SEND_REMAINDER_FINISH: {
        draft.loading = false;
        break;
      }
      // skip default
    }
  });

export default userReducer;
