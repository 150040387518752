export const getTurnoverContractPartners = async (
  fetch,
  { supplierContractId, year, period },
) => {
  return await fetch(`/api/v1/partners/turnover/contracts`, {
    method: "GET",
    params: {
      supplierContractId,
      year,
      period,
    },
  });
};

export const reportsLossesGet = async (fetch, params) => {
  return await fetch(`/api/v1/reports/losses`, {
    method: "GET",
    params,
  });
};

export const reportsSuppliersGet = async (fetch, params) => {
  return await fetch("/api/v1/reports/suppliers", {
    method: "GET",
    params,
  });
};

export const reportsPartnersGet = async (fetch, params) => {
  return await fetch(`/api/v1/reports/partners`, {
    method: "GET",
    params,
  });
};

export const reportsSuppliersPartnersGet = async (fetch, params) => {
  return await fetch("/api/v1/reports/suppliersPartners", {
    method: "GET",
    params,
  });
};
