import {
  PERIOD_LIST_GET_FAILURE,
  PERIOD_LIST_GET_START,
  PERIOD_LIST_GET_SUCCESS,
  CATEGORY_LIST_GET_FAILURE,
  CATEGORY_LIST_GET_START,
  CATEGORY_LIST_GET_SUCCESS,
  PERIOD_LIST_SELECT_PERIOD,
  PERIOD_LIST_SELECT_YEAR,
  CLASSIFIER_SEARCH_FAILURE,
  CLASSIFIER_SEARCH_PREVIEW_GET,
  CLASSIFIER_SEARCH_START,
  CLASSIFIER_SEARCH_SUCCESS,
  CATEGORY_CREATE_START,
  CATEGORY_CREATE_END,
  CATEGORY_UPDATE_START,
  CATEGORY_UPDATE_END,
} from "../constants/actionTypes";
import { closeDialog, openDialog } from "redux-reactstrap-modal";
import { getMessageFromState } from "../../utils/intl";
import { toastr } from "react-redux-toastr";

/**
 * Fetches all periods
 * @returns {Function}
 */
export function fetchPeriods(period) {
  return async (dispatch, getState, { fetch }) => {
    try {
      dispatch({
        type: PERIOD_LIST_GET_START,
        payload: {},
      });
      let periods = await fetch(`/api/v1/classifiers/periods`, {
        method: "GET",
      });
      if (period && period.year && period.period) {
        periods = {
          ...periods,
          selected: { year: period.year, period: period.period },
        };
      }
      dispatch({
        type: PERIOD_LIST_GET_SUCCESS,
        payload: periods,
      });
    } catch (err) {
      dispatch({
        type: PERIOD_LIST_GET_FAILURE,
        payload: {},
      });
    }
  };
}

export function updatePeriodSelection(value, type) {
  return async dispatch => {
    switch (type) {
      case "Y":
        dispatch({
          type: PERIOD_LIST_SELECT_YEAR,
          payload: value,
        });
        break;
      case "P":
        dispatch({
          type: PERIOD_LIST_SELECT_PERIOD,
          payload: value,
        });
        break;
    }
  };
}

export function searchPreviewClassifiers(searchString) {
  return async (dispatch, getState, { fetch }) => {
    try {
      const classifiers = await fetch(`/api/v1/classifiers/search`, {
        method: "GET",
        params: {
          preview: true,
          searchString,
        },
      });
      dispatch({
        type: CLASSIFIER_SEARCH_PREVIEW_GET,
        payload: classifiers,
      });
    } catch (err) {
      console.error(err);
    }
  };
}

export function searchClassifiers(searchString) {
  return async (dispatch, getState, { fetch }) => {
    try {
      dispatch({
        type: CLASSIFIER_SEARCH_START,
        payload: {},
      });
      const classifiers = await fetch(`/api/v1/classifiers/search`, {
        method: "GET",
        params: {
          preview: false,
          searchString,
        },
      });
      dispatch({
        type: CLASSIFIER_SEARCH_SUCCESS,
        payload: classifiers,
      });
    } catch (err) {
      dispatch({
        type: CLASSIFIER_SEARCH_FAILURE,
        payload: {},
      });
    }
  };
}

export function fetchCategories() {
  return async (dispatch, getState, { fetch }) => {
    try {
      dispatch({
        type: CATEGORY_LIST_GET_START,
        payload: {},
      });
      const periods = await fetch(`/api/v1/classifiers/categories`, {
        method: "GET",
      });
      dispatch({
        type: CATEGORY_LIST_GET_SUCCESS,
        payload: periods,
      });
    } catch (err) {
      dispatch({
        type: CATEGORY_LIST_GET_FAILURE,
        payload: {},
      });
    }
  };
}

export function createCategory(body) {
  return async (dispatch, getState, { fetch }) => {
    try {
      dispatch({
        type: CATEGORY_CREATE_START,
        payload: {},
      });
      await fetch(`/api/v1/classifiers/categories`, {
        method: "POST",
        body,
      });
      toastr.success(getMessageFromState("toastr.category.created"));
      dispatch(fetchCategories());
    } catch (err) {
      dispatch({
        type: CATEGORY_CREATE_END,
        payload: {},
      });
    }
  };
}

export function updateCategory(body, id) {
  return async (dispatch, getState, { fetch }) => {
    try {
      dispatch({
        type: CATEGORY_UPDATE_START,
        payload: {},
      });
      await fetch(`/api/v1/classifiers/categories`, {
        method: "PATCH",
        body: { ...body, id },
      });
      toastr.success(getMessageFromState("toastr.category.updated"));
      dispatch(fetchCategories());
    } catch (err) {
      dispatch({
        type: CATEGORY_UPDATE_END,
        payload: {},
      });
    }
  };
}

/**
 * Prompt to issue registration token
 * @returns {Function}
 */
export function openCategoryModal(props) {
  return async function(dispatch) {
    const title = "modal.categories.title";

    dispatch(
      openDialog("category-modal", {
        title,
        ...props,
        reject: () => dispatch(closeDialog("category-modal")),
      }),
    );
  };
}
