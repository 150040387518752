import { combineReducers } from "redux";
import authReducer from "./authReducer";
import { intlReducer } from "react-intl-redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import partnerReducer from "./partnerReducer";
import supplierReducer from "./supplierReducer";
import organizationReducer from "./organizationReducer";
import userReducer from "./userReducer";
import { dialogReducer } from "redux-reactstrap-modal";
import periodReducer from "./periodReducer";
import exportReducer from "./exportReducer";
import controlPanelReducer from "./controlPanelReducer";
import globalReducer from "./globalReducer";
import classifierReducer from "./classifierReducer";
import reportReducer from "./reportReducer";
import turnoverReducer from "./turnoverReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  intl: intlReducer,
  toastr: toastrReducer,
  partners: partnerReducer,
  suppliers: supplierReducer,
  organization: organizationReducer,
  dialogReducer,
  users: userReducer,
  periods: periodReducer,
  export: exportReducer,
  controlPanel: controlPanelReducer,
  global: globalReducer,
  classifiers: classifierReducer,
  reports: reportReducer,
  turnovers: turnoverReducer,
});
