import {
  PERIOD_LIST_GET_START,
  PERIOD_LIST_GET_SUCCESS,
  PERIOD_LIST_GET_FAILURE,
  PERIOD_LIST_SELECT_PERIOD,
  PERIOD_LIST_SELECT_YEAR,
} from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  periods: [],
  years: [],
};

/* eslint-disable default-case */
/**
 * Redux reducer for suppliers
 * @param state
 * @param action
 */
const periodReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { payload, type } = action;
    switch (type) {
      case PERIOD_LIST_GET_FAILURE:
      case PERIOD_LIST_GET_START: {
        draft.periods = [];
        draft.years = [];
        break;
      }

      case PERIOD_LIST_GET_SUCCESS: {
        draft.periods = payload.periods;
        draft.years = payload.years;
        break;
      }

      // skip default
    }
  });

export default periodReducer;
