import io from "socket.io-client";
import { store } from "../index";
import { toastr } from "react-redux-toastr";
import { globalLoading, globalLoadingFinish } from "./actions/globalActions";
import { getMessageFromState } from "../utils/intl";
import config from "../config/config";
import localStorage from "./localStorage";
import socketTypes from "./constants/socketTypes";

export default class Socket {
  /**
   * Connects to server via socket.io
   * and binds actions on socket
   */
  async connect(onSuccess) {
    if (this.socket) {
      await this.socket.disconnect();
    }
    let url;
    if (window.location.hostname === "localhost") {
      url = config.backend.url;
    } else {
      url = window.location.origin;
    }

    const token = localStorage.get("token");
    this.socket = await io.connect(
      url,
      {
        path: "/sockjs-node",
        query: { token },
      },
    );

    this.socket.on(socketTypes.CONNECT, () => {
      console.debug("The socket is connected: ", this.socket.connected);
    });
    this.socket.on(socketTypes.DISCONNECT, () => {
      store.dispatch(globalLoadingFinish());
    });

    this.socket.on(socketTypes.TURNOVERS_UPDATED, this.turnoversUpdated);
    this.socket.on(
      socketTypes.TURNOVERS_RECALCULATED,
      this.turnoversRecalculated,
    );
    this.socket.on(
      socketTypes.TURNOVERS_UPDATE_FAILURE,
      this.turnoverUpdateFailure,
    );
    this.socket.on(socketTypes.TURNOVERS_UPDATE_SUCCESS, () =>
      this.turnoverUpdateSuccess(onSuccess),
    );
    this.socket.on(socketTypes.CLIENT_DISCONNECT, this.disconnect);
    this.socket.on(socketTypes.FILE_RETRIEVED, this.turnoverFileRetrieved);
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  // Emit action to start upload
  turnoversUpload = params => {
    if (this.socket) {
      this.socket.emit(socketTypes.TURNOVERS_UPLOAD, params);
      store.dispatch(globalLoading());
    }
  };

  // Message when file is found
  turnoverFileRetrieved = () => {
    toastr.info(getMessageFromState("toastr.turnover.upload.file.retrieved"));
  };

  // Message when turnovers are uploaded
  turnoversUpdated = () => {
    toastr.info(
      getMessageFromState("toastr.turnover.upload.turnovers.updated"),
    );
  };

  // Message when turnover values are recalculated
  turnoversRecalculated = () => {
    toastr.info(
      getMessageFromState("toastr.turnover.upload.turnovers.recalculated"),
    );
  };

  // Message when turnovers are successfully imported
  // Fires an onSuccess function
  turnoverUpdateSuccess = onSuccess => {
    toastr.success(
      getMessageFromState("toastr.turnover.upload.turnovers.success"),
    );
    onSuccess();
    store.dispatch(globalLoadingFinish());
    this.disconnect();
  };

  // Error message
  turnoverUpdateFailure = errMsg => {
    let msg;
    console.log(errMsg);
    if (
      errMsg === "api.error.turnover.due.date" ||
      errMsg === "api.error.edit.extracted" ||
      errMsg === "api.error.turnover.periods.should.be.equal" ||
      errMsg === "api.error.turnover.contract.expired" ||
      errMsg === "api.error.turnover.contract.archived"

    ) {
      msg = getMessageFromState(errMsg);
    } else {
      msg = getMessageFromState("toastr.turnover.upload.turnovers.failure");
    }
    toastr.error(msg);
    store.dispatch(globalLoadingFinish());
    this.disconnect();
  };
}
