import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers/rootReducer";
import createHelpers from "./createHelpers";
import { connectRouter, routerMiddleware } from "connected-react-router";
import localStorage from "./localStorage";
import config from "../config/config";

/**
 * Redux store export
 * @returns {Store<*, Action> & {dispatch: any}}
 */
export default function configureStore(initialState, history, socket) {
  // Grabs auth data from localstorage and sets it as react state
  const tokenStore = localStorage.get("token");
  if (tokenStore) {
    initialState.auth = { token: tokenStore };
  }

  const helpers = createHelpers({ history, socket });
  const middleware = [thunk.withExtraArgument(helpers)];

  if (config.environment !== "production") {
    middleware.push(require("redux-immutable-state-invariant").default());
    middleware.push(require("redux-logger").createLogger());
  }

  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    applyMiddleware(...middleware, routerMiddleware(history)),
  );

  store.subscribe(() => {
    const token = store.getState().auth.token;
    if (token) {
      localStorage.set("token", token);
    } else {
      localStorage.set("token", null);
    }
  });

  return store;
}
