export const SUPPLIER_CONTRACT_LIST_RESET = "SUPPLIER_CONTRACT_LIST_RESET";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_IN_PROGRESS = "AUTH_LOGIN_IN_PROGRESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_TOKEN_CHANGED = "AUTH_TOKEN_CHANGED";
export const AUTH_USER_DATA_GET = "AUTH_USER_DATA_GET";
export const AUTH_PASSWORD_RESET_REQUIRED = "AUTH_PASSWORD_RESET_REQUIRED";
export const DEFAULT_ORGANIZATION_UPDATE = "DEFAULT_ORGANIZATION_UPDATE";

export const ORGANIZATION_LIST_GET_START = "ORGANIZATION_LIST_GET_START";
export const ORGANIZATION_LIST_GET_SUCCESS = "ORGANIZATION_LIST_GET_SUCCESS";
export const ORGANIZATION_LIST_GET_FAILURE = "ORGANIZATION_LIST_GET_FAILURE";
export const ORGANIZATION_GET_START = "ORGANIZATION_GET_START";
export const ORGANIZATION_GET_SUCCESS = "ORGANIZATION_GET_SUCCESS";
export const ORGANIZATION_GET_FAILURE = "ORGANIZATION_GET_FAILURE";
export const ORGANIZATION_CREATE_START = "ORGANIZATION_CREATE_START";
export const ORGANIZATION_CREATE_SUCCESS = "ORGANIZATION_CREATE_SUCCESS";
export const ORGANIZATION_CREATE_FAILURE = "ORGANIZATION_CREATE_FAILURE";
export const ORGANIZATION_TEXT_UPDATE = "ORGANIZATION_TEXT_UPDATE";
export const ORGANIZATION_UPDATE_START = "ORGANIZATION_UPDATE_START";
export const ORGANIZATION_UPDATE_SUCCESS = "ORGANIZATION_UPDATE_SUCCESS";
export const ORGANIZATION_UPDATE_FAILURE = "ORGANIZATION_UPDATE_FAILURE";

export const PARTNER_GET_START = "PARTNER_GET_START";
export const PARTNER_GET_SUCCESS = "PARTNER_GET_SUCCESS";
export const PARTNER_GET_FAILURE = "PARTNER_GET_FAILURE";
export const PARTNER_LIST_GET_START = "PARTNER_LIST_GET_START";
export const PARTNER_LIST_GET_SUCCESS = "PARTNER_LIST_GET_SUCCESS";
export const PARTNER_LIST_GET_FAILURE = "PARTNER_LIST_GET_FAILURE";
export const PARTNER_LIST_FIELD_UPDATE = "PARTNER_LIST_FIELD_UPDATE";
export const PARTNER_CONTRACT_LIST_GET_START =
  "PARTNER_CONTRACT_LIST_GET_START";
export const PARTNER_CONTRACT_LIST_GET_SUCCESS =
  "PARTNER_CONTRACT_LIST_GET_SUCCESS";
export const PARTNER_CONTRACT_LIST_GET_FAILURE =
  "PARTNER_CONTRACT_LIST_GET_FAILURE";
export const PARTNER_CONTRACT_UPDATE_START = "PARTNER_CONTRACT_UPDATE_START";
export const PARTNER_CONTRACT_UPDATE_SUCCESS =
  "PARTNER_CONTRACT_UPDATE_SUCCESS";
export const PARTNER_CONTRACT_UPDATE_FAILURE =
  "PARTNER_CONTRACT_UPDATE_FAILURE";

export const PARTNER_VOLUME_DISCOUNT_GET_FAILURE =
  "PARTNER_VOLUME_DISCOUNT_GET_FAILURE";
export const PARTNER_VOLUME_DISCOUNT_GET_SUCCESS =
  "PARTNER_VOLUME_DISCOUNT_GET_SUCCESS";
export const PARTNER_VOLUME_DISCOUNT_GET_START =
  "PARTNER_VOLUME_DISCOUNT_GET_START";
export const PARTNER_VOLUME_DISCOUNT_RESET = "PARTNER_VOLUME_DISCOUNT_RESET";
export const PARTNER_REPORTS_GET_START = "PARTNER_REPORTS_GET_START";
export const PARTNER_REPORTS_GET_SUCCESS = "PARTNER_REPORTS_GET_SUCCESS";
export const PARTNER_REPORTS_GET_FAILURE = "PARTNER_REPORTS_GET_FAILURE";
export const PARTNER_REPORTS_DELETE_START = "PARTNER_REPORTS_DELETE_START";
export const PARTNER_REPORTS_DELETE_SUCCESS = "PARTNER_REPORTS_DELETE_SUCCESS";
export const PARTNER_REPORTS_DELETE_FAILURE = "PARTNER_REPORTS_DELETE_FAILURE";
export const PARTNER_LIST_RESET = "PARTNER_LIST_RESET";
export const PARTNER_CONTRACTS_RESET = "PARTNER_CONTRACTS_RESET";

export const SUPPLIER_GET_START = "SUPPLIER_GET_START";
export const SUPPLIER_GET_SUCCESS = "SUPPLIER_GET_SUCCESS";
export const SUPPLIER_GET_FAILURE = "SUPPLIER_GET_FAILURE";
export const SUPPLIER_LIST_GET_START = "SUPPLIER_LIST_GET_START";
export const SUPPLIER_LIST_GET_SUCCESS = "SUPPLIER_LIST_GET_SUCCESS";
export const SUPPLIER_LIST_GET_FAILURE = "SUPPLIER_LIST_GET_FAILURE";
export const SUPPLIER_CONTRACT_LIST_GET_START =
  "SUPPLIER_CONTRACT_LIST_GET_START";
export const SUPPLIER_CONTRACT_LIST_GET_SUCCESS =
  "SUPPLIER_CONTRACT_LIST_GET_SUCCESS";
export const SUPPLIER_CONTRACT_LIST_GET_FAILURE =
  "SUPPLIER_CONTRACT_LIST_GET_FAILURE";
export const SUPPLIER_CONTRACT_UPDATE_START = "SUPPLIER_CONTRACT_UPDATE_START";
export const SUPPLIER_CONTRACT_UPDATE_SUCCESS =
  "SUPPLIER_CONTRACT_UPDATE_SUCCESS";
export const SUPPLIER_CONTRACT_UPDATE_FAILURE =
  "SUPPLIER_CONTRACT_UPDATE_FAILURE";
export const SUPPLIER_REPORTS_GET_START = "SUPPLIER_REPORTS_GET_START";
export const SUPPLIER_REPORTS_GET_SUCCESS = "SUPPLIER_REPORTS_GET_SUCCESS";
export const SUPPLIER_REPORTS_GET_FAILURE = "SUPPLIER_REPORTS_GET_FAILURE";
export const SUPPLIER_CONTRACT_DELETE_START = "SUPPLIER_CONTRACT_DELETE_START";
export const SUPPLIER_CONTRACT_DELETE_SUCCESS =
  "SUPPLIER_CONTRACT_DELETE_SUCCESS";
export const SUPPLIER_CONTRACT_DELETE_FAILURE =
  "SUPPLIER_CONTRACT_DELETE_FAILURE";
export const USER_LIST_GET_START = "USER_LIST_GET_START";
export const USER_LIST_GET_SUCCESS = "USER_LIST_GET_SUCCESS";
export const USER_LIST_GET_FAILURE = "USER_LIST_GET_FAILURE";
export const USER_LIST_LOADING = "USER_LIST_LOADING";
export const USER_LIST_NOT_LOADING = "USER_LIST_NOT_LOADING";
export const USER_GET_START = "USER_GET_START";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILURE = "USER_GET_FAILURE";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_START = "USER_UPDATE_START";
export const USER_CREATE_START = "USER_CREATE_START";
export const USER_CREATE_FAILURE = "USER_CREATE_FAILURE";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_SUBMIT_GLOBAL_PROGRESS = "USER_SUBMIT_GLOBAL_PROGRESS";
export const USER_SUBMIT_GLOBAL_FINISH = "USER_SUBMIT_GLOBAL_FINISH";
export const USER_CURRENT_UPDATE_START = "USER_CURRENT_UPDATE_START";
export const USER_CURRENT_UPDATE_SUCCESS = "USER_CURRENT_UPDATE_SUCCESS";
export const USER_CURRENT_UPDATE_FAILURE = "USER_CURRENT_UPDATE_FAILURE";
export const USER_ITEM_RESET = "USER_ITEM_RESET";
export const USER_DELETE_START = "USER_DELETE_START";
export const USER_DELETE_END = "USER_DELETE_END";
export const USER_MISSING_CONTRACT_LIST_GET_START = "USER_MISSING_CONTRACT_LIST_GET_START";
export const USER_MISSING_CONTRACT_LIST_GET_SUCCESS = "USER_MISSING_CONTRACT_LIST_GET_SUCCESS";
export const USER_MISSING_CONTRACT_LIST_GET_FAILURE = "USER_MISSING_CONTRACT_LIST_GET_FAILURE";
export const USER_MISSING_CONTRACT_LIST_CLEAR = "USER_MISSING_CONTRACT_LIST_CLEAR";
export const USER_EMAIL_SEND_REMAINDER_START = "USER_EMAIL_SEND_REMAINDER_START";
export const USER_EMAIL_SEND_REMAINDER_FINISH = "USER_EMAIL_SEND_REMAINDER_FINISH";

export const PERIOD_LIST_GET_START = "PERIOD_LIST_GET_START";
export const PERIOD_LIST_GET_SUCCESS = "PERIOD_LIST_GET_SUCCESS";
export const PERIOD_LIST_GET_FAILURE = "PERIOD_LIST_GET_FAILURE";
export const PERIOD_LIST_SELECT_PERIOD = "PERIOD_LIST_SELECT_PERIOD";
export const PERIOD_LIST_SELECT_YEAR = "PERIOD_LIST_SELECT_YEAR";

export const EXPORT_LOADING = "EXPORT_LOADING";
export const EXPORT_FINISH = "EXPORT_FINISH";

export const PANEL_CONTRACT_LIST_GET_START = "PANEL_CONTRACT_LIST_GET_START";
export const PANEL_CONTRACT_LIST_GET_SUCCESS =
  "PANEL_CONTRACT_LIST_GET_SUCCESS";
export const PANEL_CONTRACT_LIST_GET_FAILURE =
  "PANEL_CONTRACT_LIST_GET_FAILURE";

export const CLASSIFIER_SEARCH_PREVIEW_GET = "CLASSIFIER_SEARCH_PREVIEW_GET";
export const CLASSIFIER_SEARCH_START = "CLASSIFIER_SEARCH_START";
export const CLASSIFIER_SEARCH_SUCCESS = "CLASSIFIER_SEARCH_SUCCESS";
export const CLASSIFIER_SEARCH_FAILURE = "CLASSIFIER_SEARCH_FAILURE";

export const SUPPLIER_CONTRACT_LIST_UPLOAD_START =
  "SUPPLIER_CONTRACT_LIST_UPLOAD_START";
export const SUPPLIER_CONTRACT_LIST_UPLOAD_END =
  "SUPPLIER_CONTRACT_LIST_UPLOAD_END";

export const PLATFORM_SETTINGS_GET_START = "PLATFORM_SETTINGS_GET_START";
export const PLATFORM_SETTINGS_GET_SUCCESS = "PLATFORM_SETTINGS_GET_SUCCESS";
export const PLATFORM_SETTINGS_GET_FAILURE = "PLATFORM_SETTINGS_GET_FAILURE";
export const PLATFORM_SETTINGS_UPDATE_START = "PLATFORM_SETTINGS_UPDATE_START";
export const PLATFORM_SETTINGS_UPDATE_END = "PLATFORM_SETTINGS_UPDATE_END";

export const CATEGORY_LIST_GET_FAILURE = "CATEGORY_LIST_GET_FAILURE";
export const CATEGORY_LIST_GET_START = "CATEGORY_LIST_GET_START";
export const CATEGORY_LIST_GET_SUCCESS = "CATEGORY_LIST_GET_SUCCESS";
export const CATEGORY_CREATE_START = "CATEGORY_CREATE_START";
export const CATEGORY_CREATE_END = "CATEGORY_CREATE_END";
export const CATEGORY_UPDATE_START = "CATEGORY_UPDATE_START";
export const CATEGORY_UPDATE_END = "CATEGORY_UPDATE_END";

export const PANEL_CONTROL_EMAIL_SEND_REMAINDER_START =
  "PANEL_CONTROL_EMAIL_SEND_REMAINDER_START";
export const PANEL_CONTROL_EMAIL_SEND_REMAINDER_FINISH =
  "PANEL_CONTROL_EMAIL_SEND_REMAINDER_FINISH";

export const LOSSES_REPORT_GET_START = "LOSSES_REPORT_GET_START";
export const LOSSES_REPORT_GET_SUCCESS = "LOSSES_REPORT_GET_SUCCESS";
export const LOSSES_REPORT_GET_FAILURE = "LOSSES_REPORT_GET_FAILURE";
export const GLOBAL_LOADING_PROGRESS = "GLOBAL_LOADING_PROGRESS";
export const GLOBAL_LOADING_FINISH = "GLOBAL_LOADING_FINISH";

export const SUPPLIER_PARTNER_REPORTS_GET_START =
  "SUPPLIER_PARTNER_REPORTS_GET_START";
export const SUPPLIER_PARTNER_REPORTS_GET_SUCCESS =
  "SUPPLIER_PARTNER_REPORTS_GET_SUCCESS";
export const SUPPLIER_PARTNER_REPORTS_GET_FAILURE =
  "SUPPLIER_PARTNER_REPORTS_GET_FAILURE";

export const PARTNER_REPORTS_RESET = "PARTNER_REPORTS_RESET";
export const SUPPLIER_PARTNER_REPORTS_RESET = "SUPPLIER_PARTNER_REPORTS_RESET";
export const SUPPLIER_REPORTS_RESET = "SUPPLIER_REPORTS_RESET";
export const LOSSES_REPORT_RESET = "LOSSES_REPORT_RESET";

export const TURNOVER_RELATED_TURNOVER_SUM_UPDATE =
  "TURNOVER_RELATED_TURNOVER_SUM_UPDATE";
export const TURNOVER_RELATED_TURNOVER_SUM_UPDATE_FAILURE =
  "TURNOVER_RELATED_TURNOVER_SUM_UPDATE_FAILURE";
export const TURNOVER_PARTNER_LIST_GET_START =
  "TURNOVER_PARTNER_LIST_GET_START";
export const TURNOVER_PARTNER_LIST_GET_SUCCESS =
  "TURNOVER_PARTNER_LIST_GET_SUCCESS";
export const TURNOVER_PARTNER_LIST_GET_FAILURE =
  "TURNOVER_PARTNER_LIST_GET_FAILURE";
export const TURNOVER_PARTNER_LIST_FIELD_UPDATE =
  "TURNOVER_PARTNER_LIST_FIELD_UPDATE";
export const TURNOVER_LOADING = "TURNOVER_LOADING";
export const TURNOVER_LOADING_STOP = "TURNOVER_LOADING_STOP";

export const AUTH_VERIFY_PROGRESS = "AUTH_VERIFY_PROGRESS";
export const AUTH_VERIFY_FINISH = "AUTH_VERIFY_FINISH";

export const SUPPLIER_CONTRACT_TURNOVER_LIST_GET_START =
  "SUPPLIER_CONTRACT_TURNOVER_LIST_GET_START";
export const SUPPLIER_CONTRACT_TURNOVER_LIST_GET_SUCCESS =
  "SUPPLIER_CONTRACT_TURNOVER_LIST_GET_SUCCESS";
export const SUPPLIER_CONTRACT_TURNOVER_LIST_GET_FAILURE =
  "SUPPLIER_CONTRACT_TURNOVER_LIST_GET_FAILURE";
export const SUPPLIER_CONTRACT_TURNOVER_LIST_RESET =
  "SUPPLIER_CONTRACT_TURNOVER_LIST_RESET";

export const FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGRESS";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAILURE = "FILE_UPLOAD_FAILURE";
export const FILE_UPLOAD_RESET = "FILE_UPLOAD_RESET";
