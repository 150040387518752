import {
  TURNOVER_RELATED_TURNOVER_SUM_UPDATE,
  TURNOVER_RELATED_TURNOVER_SUM_UPDATE_FAILURE,
  TURNOVER_PARTNER_LIST_GET_START,
  TURNOVER_PARTNER_LIST_GET_SUCCESS,
  TURNOVER_PARTNER_LIST_GET_FAILURE,
  TURNOVER_PARTNER_LIST_FIELD_UPDATE,
  TURNOVER_LOADING,
  TURNOVER_LOADING_STOP,
  SUPPLIER_CONTRACT_TURNOVER_LIST_GET_START,
  SUPPLIER_CONTRACT_TURNOVER_LIST_GET_SUCCESS,
  SUPPLIER_CONTRACT_TURNOVER_LIST_GET_FAILURE,
  SUPPLIER_CONTRACT_TURNOVER_LIST_RESET,
} from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  turnoverSum: 0,
  lossAmount: 0,
  discountAmount: 0,
  totalAmount: 0,
  relatedTurnoverSum: 0,
  currentAndRelatedTurnoverSum: 0,
  contractList: [],
  initialContractListFilled: null,
  loading: false,
  submitPeriod: {
    year: null,
    period: null,
  },
  supplierContractTurnoverList: [],
  allowSubmit: false,
};

/* eslint-disable default-case */
/**
 * Redux reducer for authorization
 * @param state
 * @param action
 */
const turnoverReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { payload, type } = action;
    switch (type) {
      case TURNOVER_RELATED_TURNOVER_SUM_UPDATE: {
        draft.turnoverSum = payload.turnoverSum;
        draft.lossAmount = payload.lossAmount;
        draft.discountAmount = payload.discountAmount;
        draft.totalAmount = payload.totalAmount;
        draft.relatedTurnoverSum = payload.relatedTurnoverSum;
        draft.currentAndRelatedTurnoverSum =
          payload.currentAndRelatedTurnoverSum;
        break;
      }
      case TURNOVER_RELATED_TURNOVER_SUM_UPDATE_FAILURE: {
        draft.turnoverSum = 0;
        draft.lossAmount = 0;
        draft.discountAmount = 0;
        draft.totalAmount = 0;
        draft.relatedTurnoverSum = 0;
        draft.currentAndRelatedTurnoverSum = 0;
        break;
      }

      case TURNOVER_PARTNER_LIST_GET_START: {
        draft.contractList = [];
        draft.initialContractListFilled = null;
        draft.loading = true;
        break;
      }

      case TURNOVER_PARTNER_LIST_GET_SUCCESS: {
        draft.contractList = payload.rows;
        draft.initialContractListFilled = payload.rows.some(
          list =>
            list.turnovers &&
            list.turnovers.some(
              turnover =>
                turnover.value !== "" &&
                turnover.value !== null &&
                turnover.value !== undefined,
            ),
        );
        draft.loading = false;
        draft.submitPeriod = payload.submitPeriod;
        break;
      }

      case TURNOVER_PARTNER_LIST_GET_FAILURE: {
        draft.contractList = [];
        draft.initialContractListFilled = null;
        draft.loading = false;
        draft.submitPeriod = {
          year: null,
          period: null,
        };
        break;
      }

      case TURNOVER_PARTNER_LIST_FIELD_UPDATE: {
        draft.contractList = payload;
        break;
      }

      case SUPPLIER_CONTRACT_TURNOVER_LIST_GET_START:
      case TURNOVER_LOADING: {
        draft.loading = true;
        break;
      }

      case SUPPLIER_CONTRACT_TURNOVER_LIST_GET_FAILURE:
      case TURNOVER_LOADING_STOP: {
        draft.loading = false;
        break;
      }

      case SUPPLIER_CONTRACT_TURNOVER_LIST_GET_SUCCESS: {
        draft.supplierContractTurnoverList = payload.data;
        draft.allowSubmit = payload.allowSubmit;
        draft.loading = false;
        break;
      }

      case SUPPLIER_CONTRACT_TURNOVER_LIST_RESET: {
        draft.supplierContractTurnoverList = [];
        break;
      }

      // skip default
    }
  });

export default turnoverReducer;
