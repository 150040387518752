import localStorage from "./localStorage";
import config from "../config/config";
import { logout, forcePasswordReset } from "./actions/authActions";
import { toastr } from "react-redux-toastr";
import { getMessageFromState } from "../utils/intl";
import createDownloadLink from "./createDownloadLink";
import queryString from "querystring";
import { store } from "../";

/**
 * Fetch functionality
 * @returns {function(*=, *=): Promise}
 */
export default function createFetch() {
  const handleLogout = () => {
    store.dispatch(logout(false));
  };

  return async function wrappedFetch(url, overrideOptions = {}) {
    //Allow setting url via config in testing
    //In production use the same hostname and same port
    if (window.location.hostname === "localhost") {
      url = config.backend.url + url;
    } else {
      url = window.location.origin + url;
    }

    const defaultOptions = {
      credentials: "include",
      crossDomain: true,
      processData: false,
    };
    const defaultHeaders = {
      "Content-Type": "application/json",
    };

    const token = localStorage.get("token");

    if (token) {
      defaultHeaders["Authorization"] = `Bearer ${token}`;
    }

    if (
      overrideOptions.body &&
      typeof overrideOptions.body !== "string" &&
      !overrideOptions.form
    ) {
      overrideOptions.body = JSON.stringify(overrideOptions.body);
    }

    const options = Object.assign(defaultOptions, overrideOptions);
    if (options.params) {
      const query = queryString.stringify(options.params);
      url = `${url}/?${query}`;
    }

    options.headers = Object.assign(defaultHeaders, options.headers);

    if (overrideOptions.form) {
      delete options.headers["Content-Type"];
    }

    const request = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          let { messages, message } = await response.json();

          if (message) {
            const errMessage = getMessageFromState(message);
            if (errMessage !== message) {
              toastr.error(getMessageFromState(message));
            }
          }

          if (messages) {
            messages = [...new Set(messages)];
            let errMsg = messages.map(msg => {
              return getMessageFromState(msg);
            });
            errMsg = errMsg.join(". ");

            toastr.error(errMsg);
          }

          if (response.status === 401) {
            handleLogout();
          }
          reject(response);
        }

        const contentType = response.headers.get("content-type");
        if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const cpr = await createDownloadLink(response);
          if (cpr === false) {
            throw response;
          }
          resolve();
        } else {
          const json = await response.json();
          if (json.passwordExpired === true) {
            store.dispatch(forcePasswordReset());
          }

          if (response.status === 401) {
            handleLogout();
          }
          resolve(json);
        }
      } catch (error) {
        if (typeof error.json === "function") {
          try {
            const json = await error.json();
            if (json.code === 401) {
              handleLogout();
            }
            reject(json);
          } catch (_error) {
            console.log("logout here: ", _error);
            reject(error);
          }
        }
      }
    });

    return request;
  };
}
