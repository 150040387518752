export default function createDownloadLink(response) {
  return new Promise(async (resolve, reject) => {
    let rFilename = response.headers.get("content-disposition");

    if (rFilename != null) {
      rFilename = rFilename.substring(rFilename.indexOf("=") + 1);
      rFilename = rFilename.replace(/"/g, "");
      rFilename = decodeURI(rFilename);
    } else {
      rFilename = "export.xlsx";
    }

    const pdfBlob = await response.blob();
    const newBlob = new Blob([pdfBlob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement("a");
    link.href = data;
    link.download = rFilename;

    document.body.appendChild(link);
    link.click();

    // Needed for this to work in FireFox
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(data);
    }, 100);

    return resolve();
  });
}
