const localStorage = window.localStorage;

/**
 * Set token in localstorage
 * @param key
 * @param value
 */
export function set(key, value) {
  try {
    if (value !== null) {
      const serialized = JSON.stringify(value);
      localStorage.setItem(`elvi_store_${key}`, serialized);
    } else {
      localStorage.removeItem(`elvi_store_${key}`);
    }
  } catch (e) {
    console.error("local storage error", e);
  }
}

/**
 *
 * Get token from localstorage
 * @param key
 * @returns {*}
 */
export function get(key) {
  try {
    return JSON.parse(localStorage.getItem(`elvi_store_${key}`));
  } catch (e) {
    console.error("local storage error", e);
    return null;
  }
}

/**
 * Remove item from localstorage
 * @param key
 */
export function unset(key) {
  try {
    localStorage.removeItem(`elvi_store_${key}`);
  } catch (e) {
    console.error("local storage error", e);
  }
}

export default { set, get, unset };
