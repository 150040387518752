import React, { Component } from "react";
import ErrorPage from "../";
import PropTypes from "prop-types";

export default class NotFound extends Component {
  static contextTypes = {
    intl: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props);
    this.intl = context.intl;
  }

  render() {
    return (
      <ErrorPage
        title={404}
        text={this.intl.formatMessage({ id: "error.404" })}
      />
    );
  }
}
