import {
  LOSSES_REPORT_GET_START,
  LOSSES_REPORT_GET_FAILURE,
  LOSSES_REPORT_GET_SUCCESS,
  SUPPLIER_REPORTS_GET_START,
  SUPPLIER_REPORTS_GET_SUCCESS,
  SUPPLIER_REPORTS_GET_FAILURE,
  PARTNER_REPORTS_GET_START,
  PARTNER_REPORTS_GET_SUCCESS,
  PARTNER_REPORTS_GET_FAILURE,
  SUPPLIER_PARTNER_REPORTS_GET_SUCCESS,
  SUPPLIER_PARTNER_REPORTS_GET_START,
  SUPPLIER_PARTNER_REPORTS_GET_FAILURE,
  PARTNER_REPORTS_RESET,
  SUPPLIER_PARTNER_REPORTS_RESET,
  SUPPLIER_REPORTS_RESET,
  LOSSES_REPORT_RESET,
} from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  loading: false, // Stored JWT
  losses: [],
  suppliers: {
    data: [],
    periods: [],
  },
  partners: {
    data: [],
    periods: [],
  },
  suppliersPartners: {
    data: [],
    periods: [],
  },
};

/* eslint-disable default-case */
/**
 * Redux reducer for authorization
 * @param state
 * @param action
 */
const reportReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { payload, type } = action;
    switch (type) {
      case LOSSES_REPORT_GET_START: {
        draft.losses = [];
        draft.loading = true;
        break;
      }
      case LOSSES_REPORT_GET_FAILURE: {
        draft.losses = [];
        draft.loading = false;
        break;
      }
      case LOSSES_REPORT_GET_SUCCESS: {
        draft.losses = payload;
        draft.loading = false;
        break;
      }

      case SUPPLIER_REPORTS_GET_START: {
        draft.suppliers.data = [];
        draft.suppliers.periods = [];
        draft.loading = true;
        break;
      }
      case SUPPLIER_REPORTS_GET_SUCCESS: {
        draft.suppliers.data = payload.reports;
        draft.suppliers.periods = payload.periods;
        draft.loading = false;
        break;
      }
      case SUPPLIER_REPORTS_GET_FAILURE: {
        draft.suppliers.data = [];
        draft.suppliers.periods = [];
        draft.loading = false;
        break;
      }

      case PARTNER_REPORTS_GET_START: {
        draft.partners.data = [];
        draft.partners.periods = [];
        draft.loading = true;
        break;
      }
      case PARTNER_REPORTS_GET_SUCCESS: {
        draft.partners.data = payload.reports;
        draft.partners.periods = payload.periods;
        draft.loading = false;
        break;
      }
      case PARTNER_REPORTS_GET_FAILURE: {
        draft.partners.data = [];
        draft.partners.periods = [];
        draft.loading = false;
        break;
      }

      case SUPPLIER_PARTNER_REPORTS_GET_START: {
        draft.suppliersPartners.data = [];
        draft.suppliersPartners.periods = [];
        draft.loading = true;
        break;
      }
      case SUPPLIER_PARTNER_REPORTS_GET_SUCCESS: {
        draft.suppliersPartners.data = payload.reports;
        draft.suppliersPartners.periods = payload.periods;
        draft.loading = false;
        break;
      }
      case SUPPLIER_PARTNER_REPORTS_GET_FAILURE: {
        draft.suppliersPartners.data = [];
        draft.suppliersPartners.periods = [];
        draft.loading = false;
        break;
      }

      case PARTNER_REPORTS_RESET: {
        draft.partners = defaultState.partners;
        break;
      }

      case SUPPLIER_PARTNER_REPORTS_RESET: {
        draft.suppliersPartners = defaultState.suppliersPartners;
        break;
      }

      case SUPPLIER_REPORTS_RESET: {
        draft.suppliers = defaultState.suppliers;
        break;
      }

      case LOSSES_REPORT_RESET: {
        draft.losses = defaultState.losses;
        break;
      }

      // skip default
    }
  });

export default reportReducer;
