import BaseLayout from "./BaseLayout";
import {
  logout,
  updateCurrentUserDefaultOrganization,
} from "../../../store/actions/authActions";
import { connect } from "react-redux";
import { redirectToDefault } from "../../../store/actions/routingActions";
import {
  backButton,
  forwardButton,
} from "../../../store/actions/globalActions";

const mapStateToProps = state => ({
  location: state.router.location,
  user: state.auth.user,
  organization: state.organization.item.organizationType,
  //Insert redux state parameters if necessarry
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  redirectToDefault: () => dispatch(redirectToDefault()),
  backButton: () => dispatch(backButton()),
  forwardButton: () => dispatch(forwardButton()),
  updateCurrentUserDefaultOrganization: obj =>
    dispatch(updateCurrentUserDefaultOrganization(obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseLayout);
