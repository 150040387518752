import {
  AUTH_LOGIN_IN_PROGRESS,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
  AUTH_TOKEN_CHANGED,
  AUTH_USER_DATA_GET,
  AUTH_VERIFY_PROGRESS,
  AUTH_VERIFY_FINISH,
  AUTH_PASSWORD_RESET_REQUIRED,
  DEFAULT_ORGANIZATION_UPDATE,
} from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  token: null, // Stored JWT
  inProgress: false, // Authorization in progress
  renderPage: true,
  user: {
    email: "",
    organization: {
      name: "",
      id: "",
    },
    defaultOrganization: {
      name: "",
      id: "",
    },
  },
  passwordResetRequired: false,
};

/* eslint-disable default-case */
/**
 * Redux reducer for authorization
 * @param state
 * @param action
 */
const authReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { payload, type } = action;
    switch (type) {
      case AUTH_LOGIN_IN_PROGRESS: {
        draft.token = false;
        draft.inProgress = true;
        draft.user = {};
        break;
      }

      case AUTH_LOGIN_SUCCESS: {
        draft.token = payload.jwt;
        draft.inProgress = false;
        draft.user = payload.user;
        break;
      }

      case AUTH_LOGOUT:
      case AUTH_LOGIN_FAILURE: {
        draft.token = null;
        draft.inProgress = false;
        break;
      }

      case AUTH_TOKEN_CHANGED: {
        draft.token = payload.token;
        break;
      }

      case AUTH_USER_DATA_GET: {
        draft.user = payload.user;
        draft.user.organizations = payload.user.organizations;
        break;
      }

      case AUTH_VERIFY_PROGRESS: {
        draft.renderPage = false;
        break;
      }

      case DEFAULT_ORGANIZATION_UPDATE: {
        draft.user.defaultOrganization.id = payload;
        const defaultOrgLabel = draft.user.organizations.find(
          x => x.id === payload,
        );
        draft.user.defaultOrganization.name = defaultOrgLabel.name;
        break;
      }

      case AUTH_VERIFY_FINISH: {
        draft.renderPage = true;
        break;
      }

      case AUTH_PASSWORD_RESET_REQUIRED: {
        draft.passwordResetRequired = payload;
        break;
      }
      // skip default
    }
  });

export default authReducer;
