import { store } from "../../index";
import {
  TURNOVER_RELATED_TURNOVER_SUM_UPDATE,
  TURNOVER_RELATED_TURNOVER_SUM_UPDATE_FAILURE,
  TURNOVER_PARTNER_LIST_GET_START,
  TURNOVER_PARTNER_LIST_GET_SUCCESS,
  TURNOVER_PARTNER_LIST_GET_FAILURE,
  TURNOVER_PARTNER_LIST_FIELD_UPDATE,
  GLOBAL_LOADING_PROGRESS,
  GLOBAL_LOADING_FINISH,
  TURNOVER_LOADING,
  TURNOVER_LOADING_STOP,
  SUPPLIER_CONTRACT_TURNOVER_LIST_GET_START,
  SUPPLIER_CONTRACT_TURNOVER_LIST_GET_SUCCESS,
  SUPPLIER_CONTRACT_TURNOVER_LIST_GET_FAILURE,
  SUPPLIER_CONTRACT_TURNOVER_LIST_RESET,
} from "../constants/actionTypes";
import { getMessageFromState } from "../../utils/intl";
import { toastr } from "react-redux-toastr";
import { getTurnoverContractPartners } from "../api/get";
import localStorage from "../localStorage";

export function calculateTurnoverSum(supplierContractId, period, turnovers) {
  return async (dispatch, getState, { fetch }) => {
    try {
      const values = await fetch(`/api/v1/turnovers/calculateValues`, {
        method: "POST",
        params: { supplierContractId, ...period },
        body: turnovers,
      });
      dispatch({
        type: TURNOVER_RELATED_TURNOVER_SUM_UPDATE,
        payload: values,
      });
    } catch (err) {
      dispatch({
        type: TURNOVER_RELATED_TURNOVER_SUM_UPDATE_FAILURE,
        payload: {},
      });
    }
  };
}

export function fetchTurnoverContractPartners(
  supplierContractId,
  { year, period },
) {
  return async (dispatch, getState, { fetch }) => {
    dispatch({
      type: TURNOVER_PARTNER_LIST_GET_START,
      payload: {},
    });
    try {
      dispatch({
        type: TURNOVER_PARTNER_LIST_GET_SUCCESS,
        payload: await getTurnoverContractPartners(fetch, {
          supplierContractId,
          year,
          period,
        }),
      });
    } catch (err) {
      dispatch({
        type: TURNOVER_PARTNER_LIST_GET_FAILURE,
        payload: {},
      });
    }
  };
}

export function updateContractPartnerValue(value, idx, type) {
  return async dispatch => {
    let contractList = JSON.parse(
      JSON.stringify(store.getState().turnovers.contractList),
    );

    // Test if value is a proper decimal, it it is, allow editing
    value = value.replace(",", ".");
    if (
      /^[+-]?((\d+(\.\d{0,2})?)|(\.\d{0,2}))$/.test(value) ||
      value === "" ||
      value === "-"
    ) {
      const repVal = value;

      // Update normal turnover row
      if (type) {
        let index = contractList[idx].turnovers.findIndex(
          el => el.networkType === type,
        );
        contractList[idx].turnovers[index].value = repVal;
      } else {
        contractList[0].turnovers[0].value = repVal;
      }
      dispatch({
        type: TURNOVER_PARTNER_LIST_FIELD_UPDATE,
        payload: contractList,
      });
    }
  };
}

export function submitTurnovers(
  supplierContractId,
  organizationId,
  turnoverPeriod,
  submitPeriod,
) {
  return async (dispatch, getState, { fetch }) => {
    try {
      const { contractList: partnerList } = getState().turnovers;
      dispatch({ type: TURNOVER_LOADING, payload: {} });
      const turnovers = partnerList.map(values => [...values.turnovers]);
      await fetch(`/api/v1/turnovers`, {
        method: "POST",
        params: {
          submitPeriod: submitPeriod.period,
          submitYear: submitPeriod.year,
          ...turnoverPeriod,
        },
        body: {
          supplierContractId,
          submitPeriod,
          turnovers,
        },
      });

      dispatch(fetchSupplierTurnoverContracts(organizationId, turnoverPeriod));
      toastr.success(
        getMessageFromState("toastr.suppler.partners.update.success"),
      );
    } catch (err) { }
    dispatch({ type: TURNOVER_LOADING_STOP, payload: {} });
  };
}

export function uploadTurnovers(
  organizationId,
  file,
  { period, year },
  submitPeriod,
  uploadId,
) {
  return async (dispatch, getState, { fetch, socket }) => {
    const onSuccess = () =>
      dispatch(
        fetchSupplierTurnoverContracts(organizationId, { period, year }),
      );
    await socket.connect(onSuccess);
    socket.turnoversUpload({
      period,
      year,
      organizationId,
      submitPeriod,
      uploadId,
    });
  };
}

/**
 * Function to get turnover supplier contracts
 * shown in turnover summary/turnover submit
 * @param id
 * @param params
 * @returns {Function}
 */
export function fetchSupplierTurnoverContracts(id, params) {
  return async (dispatch, getState, { fetch }) => {
    const defaultOrgId = getState().auth.user.defaultOrganization.id;
    const type = getState().auth.user.organizations[0].type;
    dispatch({
      type: SUPPLIER_CONTRACT_TURNOVER_LIST_GET_START,
      payload: {},
    });
    params = { defaultOrgId, ...params };

    try {
      const supplierData = await fetch(
        `/api/v1/turnover/supplier/contracts${
        !!id && type !== "S" ? `/${id}` : ""
        }`,
        {
          method: "GET",
          params,
        },
      );
      dispatch({
        type: SUPPLIER_CONTRACT_TURNOVER_LIST_GET_SUCCESS,
        payload: {
          data: supplierData.rows,
          allowSubmit: supplierData.allowSubmit,
        },
      });
    } catch (err) {
      dispatch({
        type: SUPPLIER_CONTRACT_TURNOVER_LIST_GET_FAILURE,
        payload: {},
      });
    }
  };
}

export const resetSupplierTurnoverContracts = () => ({
  type: SUPPLIER_CONTRACT_TURNOVER_LIST_RESET,
});
