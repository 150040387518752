import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_IN_PROGRESS,
  AUTH_LOGOUT,
  AUTH_TOKEN_CHANGED,
  AUTH_USER_DATA_GET,
  AUTH_VERIFY_FINISH,
  AUTH_VERIFY_PROGRESS,
  AUTH_PASSWORD_RESET_REQUIRED,
  GLOBAL_LOADING_PROGRESS,
  GLOBAL_LOADING_FINISH,
  DEFAULT_ORGANIZATION_UPDATE,
} from "../constants/actionTypes";
import { redirectToDefault, redirectToLogin } from "./routingActions";
import config from "../../config/config";
import localStorage from "../localStorage";
import { getMessageFromState } from "../../utils/intl";
import { toastr } from "react-redux-toastr";
import { dispatch } from "rxjs/internal/observable/pairs";

/**
 * Submit authorization data to the API
 * @returns {Function}
 */
export function submitAuth(email, password, captchaToken) {
  return async function(dispatch, getState, { fetch }) {
    try {
      dispatch({
        type: AUTH_LOGIN_IN_PROGRESS,
        payload: {},
      });
      const { jwt, success, meta, user, code, passwordExpired } = await fetch(
        config.backend.routes.login,
        {
          method: "POST",
          body: {
            email,
            password,
            captchaToken,
          },
        },
      );

      if (!success || !user || code === 401) {
        throw new Error("Auth error");
      }
      localStorage.set("meta", meta);

      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        payload: {
          jwt,
          user,
        },
      });
      if (passwordExpired) {
        dispatch(forcePasswordReset());
      } else {
        dispatch(redirectToDefault(true));
      }
    } catch (err) {
      dispatch({
        type: AUTH_LOGIN_FAILURE,
        payload: {},
      });
    }
  };
}

/**
 * Function which calls onto api to check if Bearer token is valid
 * @returns {Function}
 */
export function verifyAuth() {
  return async (dispatch, getState, { fetch, history }) => {
    const { pathname } = history.location;
    const { token, user } = getState().auth;
    dispatch({ type: AUTH_VERIFY_PROGRESS });

    if (pathname === "/login" && !token) {
      await dispatch(redirectToLogin());
    } else if (token) {
      try {
        let data = await fetch(config.backend.routes.verify, {
          method: "GET",
        });
        if (data.token !== localStorage.get("token")) {
          dispatch({
            type: AUTH_TOKEN_CHANGED,
            payload: {
              token: data.token,
            },
          });
          localStorage.set("token", data.token);
        }

        if (!user && data.user) {
          dispatch({
            type: AUTH_USER_DATA_GET,
            payload: {
              user: data.user,
            },
          });
        }

        if (pathname === "/login" && !data.passwordExpired) {
          await dispatch(redirectToDefault(true));
        } else if (data.passwordExpired) {
          dispatch(forcePasswordReset());
        }
      } catch (err) {
        localStorage.unset("token");
        dispatch({
          type: AUTH_LOGOUT,
          payload: {},
        });
        await dispatch(redirectToLogin());
      }
    }
    dispatch({ type: AUTH_VERIFY_FINISH });
  };
}

/**
 * Removes localstorage values,
 * Removes token from backend,
 * Removes redux user state
 * @returns {Function}
 */
export function logout(sendToApi = true) {
  return async (dispatch, getState, { fetch }) => {
    if (sendToApi) {
      await fetch(config.backend.routes.logout, {
        method: "POST",
      });
    }
    localStorage.unset("token");
    dispatch({
      type: AUTH_LOGOUT,
      payload: {},
    });
    dispatch(redirectToLogin());
  };
}

export const forcePasswordReset = () => {
  return async (dispatch, getState) => {
    const {
      auth: { passwordResetRequired },
    } = getState();
    if (passwordResetRequired) {
      toastr.info(getMessageFromState("toastr.password.change.required"));
    }
    dispatch({
      type: AUTH_PASSWORD_RESET_REQUIRED,
      payload: true,
    });
  };
};

export const changePassword = ({
  currentPassword,
  password,
  repeatPassword,
}) => {
  return async (dispatch, getState, { fetch }) => {
    dispatch({
      type: GLOBAL_LOADING_PROGRESS,
    });
    try {
      await fetch("/api/v1/users/password", {
        method: "PATCH",
        body: {
          currentPassword,
          password,
          repeatPassword,
        },
      });
      dispatch({
        type: AUTH_PASSWORD_RESET_REQUIRED,
        payload: false,
      });
      toastr.success(getMessageFromState("toastr.password.change.success"));
    } catch (err) {
      console.error(err);
    }
    dispatch({
      type: GLOBAL_LOADING_FINISH,
    });
    dispatch(redirectToDefault(true));
  };
};

export const updateCurrentUserDefaultOrganization = obj => ({
  type: DEFAULT_ORGANIZATION_UPDATE,
  payload: Number(obj),
});
