import { EXPORT_LOADING, EXPORT_FINISH } from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  loading: false,
};

/* eslint-disable default-case */
/**
 * Redux reducer for routes
 * @param state
 * @param action
 */
const exportReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { type } = action;
    switch (type) {
      case EXPORT_LOADING: {
        draft.loading = true;
        break;
      }
      case EXPORT_FINISH: {
        draft.loading = false;
        break;
      }

      // skip default
    }
  });

export default exportReducer;
