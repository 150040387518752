import { getMessageFromState } from "../utils/intl";

/**
 * Route configuration(shown in navigation)
 * Later should be refactored to push only required routes
 * @param navItems []
 * @returns {{items: *[]}}
 */
export default function(navItems) {
  const items = [];
  const populateNavigation = (url, message, icon) => {
    if (navItems.includes(url)) {
      items.push({
        name: getMessageFromState(message),
        url,
        icon,
      });
    }
  };
  if (navItems) {
    populateNavigation("/panel", "route.panel", "icon-note");
    populateNavigation(
      "/admin/users",
      "route.administration.users",
      "icon-people",
    );
    if (navItems.includes("/admin/organizations")) {
      items.push({
        name: getMessageFromState("route.administration.organizations"),
        url: "/admin/organizations",
        icon: "icon-home",
      });
    }
    populateNavigation(
      "/admin/settings",
      "route.administration.platform.settings",
      "icon-wrench",
    );
    if (navItems.includes("/reports/supplier")) {
      items.push({
        name: getMessageFromState("route.suppliers.report"),
        url: "/reports/supplier",
        icon: "icon-note",
      });
    }
    if (navItems.includes("/reports/partner")) {
      items.push({
        name: getMessageFromState("route.partners.report"),
        url: "/reports/partner",
        icon: "icon-note",
      });
    }
    populateNavigation(
      "/reports/supplierPartner",
      "route.supplier.partner.report",
      "icon-note",
    );
    populateNavigation("/reports/losses", "route.losses.report", "icon-note");
    if (
      navItems.includes("/supplier/:id") &&
      !navItems.includes("/supplier") &&
      !navItems.includes("/panel")
    ) {
      items.push({
        name: getMessageFromState("route.supplier.card"),
        url: "/supplier/card",
        icon: "icon-basket-loaded",
      });
    }
    if (navItems.includes("/supplier")) {
      items.push({
        name: getMessageFromState("route.suppliers"),
        url: "/supplier",
        icon: "icon-basket-loaded",
      });
    }
    if (navItems.includes("/partner")) {
      items.push({
        name: getMessageFromState("route.partners"),
        url: "/partner",
        icon: "icon-user",
      });
    }
    if (navItems.includes("/contracts/supplier")) {
      items.push({
        name: getMessageFromState("route.contracts.supplier"),
        url: "/contracts/supplier",
        icon: "icon-note",
      });
    }
    if (navItems.includes("/contracts/partner")) {
      items.push({
        name: getMessageFromState("route.contracts.partner"),
        url: "/contracts/partner",
        icon: "icon-note",
      });
    }
    if (navItems.includes("/turnover/submit")) {
      items.push({
        name: getMessageFromState("route.turnover.submit"),
        url: "/turnover/submit",
        icon: "icon-note",
      });
    }
    if (navItems.includes("/turnover/summary")) {
      items.push({
        name: getMessageFromState("route.turnover.summary"),
        url: "/turnover/summary",
        icon: "icon-note",
      });
    }
    if (navItems.includes("/turnover/archive")) {
      items.push({
        name: getMessageFromState("route.turnover.archive"),
        url: "/turnover/archive",
        icon: "icon-note",
      });
    }

    if (navItems.includes("/discounts/partner")) {
      items.push({
        name: getMessageFromState("route.partners.discounts.total"),
        url: "/discounts/partner",
        icon: "icon-calculator",
      });
    }
  }

  return { items };
}
