import {
  ORGANIZATION_LIST_GET_START,
  ORGANIZATION_LIST_GET_SUCCESS,
  ORGANIZATION_LIST_GET_FAILURE,
  ORGANIZATION_GET_START,
  ORGANIZATION_GET_SUCCESS,
  ORGANIZATION_GET_FAILURE,
  ORGANIZATION_TEXT_UPDATE,
  ORGANIZATION_UPDATE_FAILURE,
  ORGANIZATION_UPDATE_SUCCESS,
  ORGANIZATION_UPDATE_START,
  ORGANIZATION_CREATE_START,
  ORGANIZATION_CREATE_FAILURE,
  ORGANIZATION_CREATE_SUCCESS,
} from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  list: {
    data: [], // User data
    page: 1,
    count: 0,
  },
  item: {
    name: "",
    address: "",
    city: {
      id: "",
      name: "",
      countryCode: "",
    },
    resident: true,
    pvnRegistrationNr: "",
    registrationNr: "",
    organizationType: "S",
    loading: false,
  },
};

/* eslint-disable default-case */
/**
 * Redux reducer for organization
 * @param state
 * @param action
 */
const organizationReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { payload, type } = action;
    switch (type) {
      case ORGANIZATION_LIST_GET_START: {
        draft.list.data = [];
        draft.list.count = 0;
        draft.list.loading = true;
        break;
      }

      case ORGANIZATION_LIST_GET_SUCCESS: {
        draft.list.data = payload.data.rows;
        draft.list.count = payload.data.count;
        draft.list.page = payload.page;
        draft.list.loading = false;
        break;
      }

      case ORGANIZATION_LIST_GET_FAILURE: {
        draft.list.data = [];
        draft.list.loading = false;
        draft.list.count = 0;
        break;
      }

      case ORGANIZATION_GET_FAILURE:
        draft.item.name = "";
        draft.item.registrationNr = "";
        draft.item.address = "";
        draft.item.loading = false;
        break;

      case ORGANIZATION_GET_START: {
        draft.item.name = "";
        draft.item.registrationNr = "";
        draft.item.address = "";
        draft.item.loading = true;
        break;
      }
      case ORGANIZATION_UPDATE_SUCCESS:
      case ORGANIZATION_GET_SUCCESS: {
        draft.item = { ...payload };
        draft.item.loading = false;
        break;
      }

      case ORGANIZATION_TEXT_UPDATE:
        draft.item[payload.name] = payload.value;
        break;

      case ORGANIZATION_UPDATE_START:
        draft.item.loading = true;
        break;

      case ORGANIZATION_UPDATE_FAILURE:
        draft.item.loading = false;
        break;

      case ORGANIZATION_CREATE_START:
        draft.item.loading = true;
        break;

      case ORGANIZATION_CREATE_SUCCESS:
      case ORGANIZATION_CREATE_FAILURE:
        draft.item.loading = false;
      // skip default
    }
  });

export default organizationReducer;
