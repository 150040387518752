import React, { Component } from "react";
import { Container } from "reactstrap";
import {
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
} from "@coreui/react";
import AppSidebarNav from "./components/SidebarNav";
import BaseHeader from "./components/BaseHeader";
import navigation from "../../../config/_nav";
import PropTypes from "prop-types";
import elviLogo from "../../../assets/img/elvi_logo.png";
import localStorage from "../../../store/localStorage";
import Loader from "../../Loader";

class BaseLayout extends Component {
  static contextTypes = {
    intl: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props);
    this.intl = context.intl;
  }

  render() {
    const { allowedRoutes } = localStorage.get("meta") || {};
    const navData = navigation(allowedRoutes, this.intl);
    const showNavigation = navData.items.length > 0;
    return (
      <div className="app">
        <Loader active={this.props.loading} style={{ zIndex: 6000 }} />
        <AppHeader fixed>
          <BaseHeader
            logout={this.props.logout}
            user={this.props.user}
            showNavigation={showNavigation}
            backButton={this.props.backButton}
            forwardButton={this.props.forwardButton}
            updateCurrentUserDefaultOrganization={
              this.props.updateCurrentUserDefaultOrganization
            }
            organization={this.props.organization}
          />
        </AppHeader>
        <div className="app-body">
          {showNavigation && (
            <AppSidebar fixed display="lg">
              <div className={"d-flex justify-content-center"}>
                <img
                  src={elviLogo}
                  width={"70%"}
                  onClick={this.props.redirectToDefault}
                  alt={"ELVI"}
                />
              </div>
              <AppSidebarHeader />
              <AppSidebarForm />
              <AppSidebarNav navConfig={navData} />
              <AppSidebarFooter />
            </AppSidebar>
          )}
          <main className="main">
            <Container fluid>{this.props.children}</Container>
          </main>
        </div>
      </div>
    );
  }
}

export default BaseLayout;
