import localStorage from "../localStorage";
import queryString from "querystring";

/**
 * Redirects to 404 page
 */
export function redirectTo404() {
  return (dispatch, getState, { history }) => {
    history.push("/404");
  };
}

/**
 * Redirects to forbidden page
 */
export function redirectTo403() {
  return (dispatch, getState, { history }) => {
    history.push("/forbidden");
  };
}

/**
 * Redirects to default page if necessarry(after login)
 * @returns {Function}
 */
export function redirectToDefault(useRedirect) {
  return (dispatch, getState, { history }) => {
    const { defaultRoute } = localStorage.get("meta");
    const redirect = url => {
      if (history.location.pathname !== url) {
        if (useRedirect) {
          history.replace(url);
        } else {
          history.push(url);
        }
      }
    };
    switch (defaultRoute) {
      case "/partner/:id":
        const orgId = getState().auth.user.organizations.id;
        redirect(`/partner/${orgId}`);
        break;
      default:
        redirect(defaultRoute);
        break;
    }
  };
}

/**
 * Redirects to login page when not authorized
 * @returns {Function}
 */
export function redirectToLogin() {
  return (dispatch, getState, { history }) => {
    history.push("/login");
  };
}

/**
 * Redirects to organization page
 * @returns {Function}
 */
export function redirectToOrganizations() {
  return (dispatch, getState, { history }) => {
    history.push("/admin/organizations");
  };
}

/**
 * Redirects to users page
 */
export function redirectToUsers() {
  return (dispatch, getState, { history }) => {
    history.push("/admin/users");
  };
}

export function redirectToSearch(searchString) {
  const query = queryString.stringify({ searchString });
  return (dispatch, getState, { history }) => {
    history.push(`/search/?${query}`);
  };
}

export function redirectToPartnerOrganization(id) {
  return (dispatch, getState, { history }) => {
    history.push(`/partner/${id}`);
  };
}

export function redirectToSupplierOrganization(id) {
  return (dispatch, getState, { history }) => {
    history.push(`/supplier/${id}`);
  };
}

export function redirectToOrganizationAdministration(id) {
  return (dispatch, getState, { history }) => {
    history.push(`/admin/organizations/${id}`);
  };
}

export function redirectToPartnerContracts(id, selectedContract, archived) {
  const query = queryString.stringify({ selectedContract, archived });
  return (dispatch, getState, { history }) => {
    history.push(`/partner/contracts/${id}/?${query}`);
  };
}

export function redirectToSupplierContracts(id, selectedContract, archived) {
  const query = queryString.stringify({ selectedContract, archived });
  return (dispatch, getState, { history }) => {
    history.push(`/supplier/contracts/${id}/?${query}`);
  };
}

export function redirectToTurnoverSubmit(id, selectedContract, turnoverPeriod) {
  const query = queryString.stringify({ selectedContract, ...turnoverPeriod });
  return (dispatch, getState, { history }) => {
    history.push(`/turnover/submit/${id}/?${query}`);
  };
}

/**
 * Replace/add specified query params
 * @param params
 * @param override set if you want to completely override query params
 * @returns {Function}
 */
export const setQueryParams = (params, override = false) => {
  return (dispatch, getState, { history }) => {
    let searchParams = {};
    const {
      location: { pathname, search },
    } = history;
    if (search && !override) {
      const paramList = search.substring(1, search.length);
      searchParams = queryString.parse(paramList);
    }
    const searchString = { ...searchParams, ...params };
    const reqQuery = queryString.stringify(searchString);
    history.replace(`${pathname}?${reqQuery}`);
  };
};
