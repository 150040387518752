import { createSelector } from "reselect";
import { getMessageFromState } from "../utils/intl";
import React from "react";

// selector
const getSearchResults = state => {
  return state;
};

// reselect function
export const searchResultSelector = createSelector(
  [getSearchResults],
  results => {
    return Object.keys(results).map(key => {
      return {
        type: key,
        value: "",
        label: getMessageFromState(`classifiers.search.${key.toLowerCase()}`),
        options: results[key].map(item => {
          const { id, name, contractNr, ...rest } = item;
          let label = name ? name : contractNr;
          if (item.organizationType || item.organizationName) {
            label += " - ";
          }
          if (item.organizationName) {
            label += item.organizationName;
          }
          if (item.organizationType) {
            label += getMessageFromState(
              `classifiers.org.type.${item.organizationType}`,
            );
          }
          return { ...rest, value: id, label, type: key };
        }),
      };
    });
  },
);
