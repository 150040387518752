import React, { Component } from "react";
import { Container, Col } from "reactstrap";
import Loader from "../../Loader/Loader";

class LoginLayout extends Component {
  render() {
    let loading;
    if (this.props.children && this.props.children.props) {
      loading = this.props.children.props.loading;
    }
    return (
      <div className="d-flex justify-content-center login">
        <Loader active={loading !== undefined ? loading : false} />
        <Col xl="4" lg="6" md="8" sm="12">
          <Container>{this.props.children}</Container>
        </Col>
      </div>
    );
  }
}

export default LoginLayout;
