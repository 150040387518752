import {
  PANEL_CONTRACT_LIST_GET_START,
  PANEL_CONTRACT_LIST_GET_SUCCESS,
  PANEL_CONTRACT_LIST_GET_FAILURE,
  PANEL_CONTROL_EMAIL_SEND_REMAINDER_FINISH,
  PANEL_CONTROL_EMAIL_SEND_REMAINDER_START,
} from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  loading: false,
  list: {
    data: [],
    page: 1,
    count: 0,
  },
  typeCount: {
    submitted: 0,
    missing: 0,
    extracted: 0,
  },
};

/* eslint-disable default-case */
/**
 * Redux reducer for routes
 * @param state
 * @param action
 */
const controlPanelReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case PANEL_CONTRACT_LIST_GET_START: {
        draft.loading = true;
        break;
      }
      case PANEL_CONTRACT_LIST_GET_SUCCESS: {
        draft.list.data = payload.rows;
        draft.list.page = payload.page;
        draft.list.count = payload.count;
        draft.typeCount = payload.typeCount;
        draft.loading = false;
        break;
      }

      case PANEL_CONTRACT_LIST_GET_FAILURE: {
        draft.loading = false;
        draft.list = defaultState.list;
        break;
      }

      case PANEL_CONTROL_EMAIL_SEND_REMAINDER_START: {
        draft.loading = true;
        break;
      }
      case PANEL_CONTROL_EMAIL_SEND_REMAINDER_FINISH: {
        draft.loading = false;
        break;
      }

      // skip default
    }
  });

export default controlPanelReducer;
