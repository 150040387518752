/**
 * Configuration which will be used
 * in production and staging
 * specific strings will be replaced
 * with the exact same environment variables
 * these variables are MANDATORY
 */
const loginRoute = "/api/v1/auth/login";
const verifyRoute = "/api/v1/auth/verify";
const logoutRoute = "/api/v1/auth/logout";
const partnerRoute = "/api/v1/partners";
const supplierRoute = "/api/v1/suppliers";
const supplierContractRoute = "/api/v1/supplier/contracts";
const organizationRoute = "/api/v1/organizations";
const userRoute = "/api/v1/users";

module.exports = {
  environment: "REPLACE_VARIABLE_ENVIRONMENT",
  recaptchaSiteKey: "REPLACE_VARIABLE_RECAPTCHA_SITE_KEY",
  appVersion: "1.4.7",
  backend: {
    routes: {
      login: loginRoute,
      verify: verifyRoute,
      logout: logoutRoute,
      partner: partnerRoute,
      supplier: supplierRoute,
      supplierContract: supplierContractRoute,
      organization: organizationRoute,
      user: userRoute,
    },
  },
  datagrid: {
    users: {
      paginationLimit: 20,
    },
    partnerOrganizationContracts: 50,
    partnerContracts: 50,
    supplierContracts: 50,
    supplierOrganizationContracts: 50,
  },
};
