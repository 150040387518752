import {
  PLATFORM_SETTINGS_GET_START,
  PLATFORM_SETTINGS_GET_SUCCESS,
  PLATFORM_SETTINGS_GET_FAILURE,
  PLATFORM_SETTINGS_UPDATE_START,
  PLATFORM_SETTINGS_UPDATE_END,
  GLOBAL_LOADING_PROGRESS,
  GLOBAL_LOADING_FINISH,
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_RESET,
} from "../constants/actionTypes";
import { getMessageFromState } from "../../utils/intl";
import { toastr } from "react-redux-toastr";
import { fetchSupplierTurnoverContracts } from "./turnoverActions";

/**
 * Submit authorization data to the API
 * @returns {Function}
 */
export function backButton(type) {
  return async function (dispatch, getState, { history }) {
    const { idx, history: historyList } = getState().global;

    if (historyList[idx - 1] !== "/login") {
      if (idx !== 0) {
        dispatch({ type: "BACK_BUTTON_CLICK" });
        history.goBack();
      }
    }
  };
}

export function forwardButton(type) {
  return async function (dispatch, getState, { history }) {
    const { idx, history: historyList } = getState().global;
    if (idx !== historyList.length) {
      dispatch({ type: "FORWARD_BUTTON_CLICK" });
      history.goForward();
    }
  };
}

export function getPlatformSettings() {
  return async function (dispatch, getState, { fetch }) {
    try {
      dispatch({ type: PLATFORM_SETTINGS_GET_START });
      const payload = await fetch(`/api/v1/platform/settings`, {
        method: "GET",
      });
      dispatch({ type: PLATFORM_SETTINGS_GET_SUCCESS, payload });
    } catch (err) {
      dispatch({ type: PLATFORM_SETTINGS_GET_FAILURE });
    }
  };
}

export function getPlatformSettingsWithoutPermissions() {
  return async function (dispatch, getState, { fetch }) {
    try {
      dispatch({ type: PLATFORM_SETTINGS_GET_START });
      const payload = await fetch(`/api/v1/platform/settingsWithoutPermissions`, {
        method: "GET",
      });
      dispatch({ type: PLATFORM_SETTINGS_GET_SUCCESS, payload });
    } catch (err) {
      dispatch({ type: PLATFORM_SETTINGS_GET_FAILURE });
    }
  };
}

export function updatePlatformSettings(body) {
  return async function (dispatch, getState, { fetch }) {
    try {
      dispatch({ type: PLATFORM_SETTINGS_UPDATE_START });
      await fetch(`/api/v1/platform/settings`, {
        method: "PATCH",
        body,
      });
      toastr.success(getMessageFromState("toastr.platform.update.success"));
    } catch (err) { }
    dispatch({ type: PLATFORM_SETTINGS_UPDATE_END });
  };
}

export function uploadFile(file) {
  return async (dispatch, getState, { fetch }) => {
    try {
      let data = new FormData();
      data.append("file", file);
      dispatch({
        type: FILE_UPLOAD_PROGRESS,
      });

      const uploadedFile = await fetch(`/api/v1/upload`, {
        method: "POST",
        body: data,
        form: true,
      });

      dispatch({
        type: FILE_UPLOAD_SUCCESS,
        payload: uploadedFile,
      });
    } catch (err) {
      toastr.error(getMessageFromState("toastr.upload.failure"));
      dispatch({
        type: FILE_UPLOAD_FAILURE,
        payload: {},
      });
    }
  };
}

export const uploadedFileReset = () => ({
  type: FILE_UPLOAD_RESET,
});

export const globalLoading = () => ({
  type: GLOBAL_LOADING_PROGRESS,
});

export const globalLoadingFinish = () => ({
  type: GLOBAL_LOADING_FINISH,
});
