import React, { Component, lazy, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import LoginLayout from "../Layouts/LoginLayout";
import BaseLayout from "../Layouts/BaseLayout";
import NotFound from "../../pages/ErrorPages/404";
import localStorage from "../../store/localStorage";
import { usePrevious } from "../../hooks/lifecycleHooks";

const ChangePassword = lazy(() => import("../../pages/ChangePassword"));

const SystemRoute = ({
  auth,
  users,
  isPrivate,
  redirectToLogin,
  redirectToDefault,
  verifyAuth,
  path,
  loading,
  layout: Layout,
  component: Component,
  routes,
  redirectTo404,
  redirectTo403,
  computedMatch,
  renderPage,
  ...rest
}) => {
  const shouldLoad = auth.inProgress || users.userGlobalLoad;
  const [preventRender, setPreventRender] = useState(true);

  //Handle redirecting to default if not logged in / handle redirect from login
  useEffect(() => {
    if (isPrivate && !auth.token) {
      redirectToLogin();
    } else if (auth.token) {
      if (["/forgot", "/reset/:id"].includes(path)) {
        redirectToDefault();
      }
    }
    setPreventRender(false);
  }, []);

  //Handle allowed routes
  useEffect(
    () => {
      const { allowedRoutes } = localStorage.get("meta") || {};
      if (Layout === BaseLayout) {
        if (
          !allowedRoutes.includes(path) &&
          !["/404", "/forbidden", "/login", "/forgot"].includes(path)
        ) {
          if (Component === NotFound) {
            redirectTo404();
          } else if (path !== "/") {
            redirectTo403();
          } else {
            redirectToDefault(true);
          }
        }
      }
    },
    [path],
  );

  const prevComputedMatch = usePrevious(computedMatch);
  useEffect(
    () => {
      if (
        prevComputedMatch &&
        computedMatch.path === prevComputedMatch.path &&
        computedMatch.params.id &&
        computedMatch.params.id !== prevComputedMatch.params.id
      ) {
        window.location.reload();
      }
    },
    [computedMatch],
  );

  return preventRender || !renderPage ? null : (
    <Route
      {...rest}
      computedMatch={computedMatch}
      render={props =>
        !auth.passwordResetRequired ? (
          <Layout loading={loading}>
            <Component {...props} loading={shouldLoad} allowedRoutes={routes} />
          </Layout>
        ) : (
          <LoginLayout loading={false}>
            <ChangePassword {...props} />
          </LoginLayout>
        )
      }
    />
  );
};

SystemRoute.defaultProps = {
  isPrivate: true,
  layout: BaseLayout,
};

SystemRoute.propTypes = {
  component: PropTypes.oneOfType([Route.propTypes.component, PropTypes.object]),
  layout: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool,
};

export default SystemRoute;
