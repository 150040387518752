import React, { Component } from "react";
import { AppSidebarToggler } from "@coreui/react";
import FontAwesome from "react-fontawesome";
import Search from "../../../Search";
import { Link, withRouter } from "react-router-dom";
import localStorage from "../../../../store/localStorage";

class BaseHeader extends Component {
  render() {
    const {
      logout,
      user,
      showNavigation,
      updateCurrentUserDefaultOrganization,
      organization,
    } = this.props;
    const { allowedRoutes } = localStorage.get("meta");
    let contentStyle = {};

    if (!allowedRoutes.includes("/search")) {
      contentStyle = {
        flexGrow: 1,
      };
    }
    return (
      <React.Fragment>
        {showNavigation && (
          <>
            <AppSidebarToggler className="d-lg-none" display="md" mobile />
            <AppSidebarToggler
              key={1}
              className="d-md-down-none"
              display="lg"
            />
          </>
        )}
        <FontAwesome
          name={"arrow-left"}
          size="lg"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
          onClick={() => this.props.backButton()}
        />
        <FontAwesome
          name={"arrow-right"}
          size="lg"
          style={{ paddingLeft: "10px" }}
          onClick={() => this.props.forwardButton()}
        />
        {allowedRoutes.includes("/search") && <Search />}
        <div className="d-block text-right mr-3 mb-2" style={contentStyle}>
          <span className="d-block">
            {user && user.organizations && user.organizations[0].type == "S" && (
              <select
                value={user.defaultOrganization.id}
                className="form-control  ml-auto default-org-dropdown"
                onChange={e => {
                  updateCurrentUserDefaultOrganization(e.target.value);
                }}
              >
                {user.organizations.map((org, idx) => (
                  <option key={idx} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>
            )}
            {user &&
              user.organizations &&
              user.organizations[0].type !== "S" &&
              user.organizations[0].name}
          </span>
          <span className="d-block">
            {user && user.email ? user.email : ""}
          </span>
        </div>
        <Link to="/settings">
          <FontAwesome name="cog" size="2x" />
        </Link>
        <FontAwesome name="sign-out" size="2x" onClick={logout} />
      </React.Fragment>
    );
  }
}

export default withRouter(BaseHeader);
