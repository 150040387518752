export default {
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  CLIENT_DISCONNECT: "socket-disconnect",
  TURNOVERS_UPDATED: "turnovers-updated",
  TURNOVERS_RECALCULATED: "turnovers-recalculated",
  TURNOVERS_UPDATE_FAILURE: "turnovers-update-failure",
  TURNOVERS_UPDATE_SUCCESS: "turnovers-update-success",
  TURNOVERS_UPLOAD: "turnovers-upload",
  FILE_RETRIEVED: "file-retrieved",
};
