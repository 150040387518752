import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import {
  redirectToOrganizationAdministration,
  redirectToPartnerContracts,
  redirectToPartnerOrganization,
  redirectToSearch,
  redirectToSupplierContracts,
  redirectToSupplierOrganization,
} from "../../store/actions/routingActions";
import { connect } from "react-redux";
import Select from "react-select";
import { searchPreviewClassifiers } from "../../store/actions/classifierActions";
import { searchResultSelector } from "../../selectors/SearchResultSelector";
import localStorage from "../../store/localStorage";

class Search extends Component {
  constructor(props, ctx) {
    super(props);
    this.state = {
      value: "",
    };
    this.onChange = this.onChange.bind(this);
  }
  onInputChange = value => {
    this.props.searchPreviewClassifiers(value);
    this.setState({
      value,
    });
  };
  componentDidMount() {
    this.props.searchPreviewClassifiers("");
  }

  onChange = selected => {
    const { allowedRoutes } = localStorage.get("meta");
    //Navigate to search
    if (selected.type === "searchAll") {
      this.props.redirectToSearch(this.state.value);
    }

    //Navigate to partner/supplier organizationPage
    if (selected.type === "organizations") {
      if (allowedRoutes.includes("/admin/organizations/:id")) {
        this.props.redirectToOrganizationAdministration(selected.value);
      } else if (selected.organizationType === "P") {
        this.props.redirectToPartnerOrganization(selected.value);
      } else {
        this.props.redirectToSupplierOrganization(selected.value);
      }
    }

    //Navigate to supplier contracts
    if (selected.type === "supplierContracts") {
      this.props.redirectToSupplierContracts(
        selected.organizationId,
        selected.value,
        selected.archived,
      );
    }

    //Navigate to partner contracts
    if (selected.type === "partnerContracts") {
      this.props.redirectToPartnerContracts(
        selected.organizationId,
        selected.value,
        selected.archived,
      );
    }
  };

  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.props.redirectToSearch(this.state.value);
    }
  };

  onSearchClick = () => {
    this.props.redirectToSearch(this.state.value);
  };

  addSearchOpt = list => {
    let newList = [
      {
        type: "searchAll",
        value: -1,
        label: `Meklēt pēc "${this.state.value}"`,
      },
      ...list,
    ];

    return newList;
  };

  render() {
    return (
      <div className={"search"}>
        <FontAwesome name="search" onClick={this.onSearchClick} />
        <Select
          name={"search"}
          createOptionPosition={"first"}
          value={this.state.value}
          options={this.addSearchOpt(this.props.searchPreview)}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          onKeyDown={this.onKeyDown}
          style={{ width: "160px" }}
          placeholder={"Meklēt"}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchPreview: searchResultSelector(state.classifiers.search.preview),
  //Insert redux state parameters if necessarry
});

const mapDispatchToProps = dispatch => ({
  redirectToSearch: searchString => dispatch(redirectToSearch(searchString)),
  searchPreviewClassifiers: searchString =>
    dispatch(searchPreviewClassifiers(searchString)),
  redirectToPartnerOrganization: id =>
    dispatch(redirectToPartnerOrganization(id)),
  redirectToSupplierOrganization: id =>
    dispatch(redirectToSupplierOrganization(id)),
  redirectToOrganizationAdministration: id =>
    dispatch(redirectToOrganizationAdministration(id)),
  redirectToPartnerContracts: (orgId, contractId, archived) =>
    dispatch(redirectToPartnerContracts(orgId, contractId, archived)),
  redirectToSupplierContracts: (orgId, contractId, archived) =>
    dispatch(redirectToSupplierContracts(orgId, contractId, archived)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);
