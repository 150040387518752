import createFetch from "./createFetch";

/**
 * Helper functions used in redux actions
 * @returns {{fetch: *}}
 */
export default function createHelpers(rest) {
  return {
    fetch: createFetch(),
    ...rest,
  };
}
