import NotFound from "./NotFound";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  //Insert redux state parameters if necessarry
});

const mapDispatchToProps = dispatch => ({
  // Insert actions here
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotFound);
