import {
  CLASSIFIER_SEARCH_PREVIEW_GET,
  CLASSIFIER_SEARCH_START,
  CLASSIFIER_SEARCH_SUCCESS,
  CLASSIFIER_SEARCH_FAILURE,
  CATEGORY_LIST_GET_FAILURE,
  CATEGORY_LIST_GET_START,
  CATEGORY_LIST_GET_SUCCESS,
  CATEGORY_CREATE_START,
  CATEGORY_CREATE_END,
  CATEGORY_UPDATE_START,
  CATEGORY_UPDATE_END,
} from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  search: {
    preview: [],
    results: [],
  },
  categories: {
    data: [],
    loading: false,
  },
  loading: false,
};

/* eslint-disable default-case */
/**
 * Redux reducer for routes
 * @param state
 * @param action
 */
const classifierReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { type } = action;
    switch (type) {
      case CLASSIFIER_SEARCH_PREVIEW_GET: {
        draft.search.preview = action.payload;
        break;
      }
      case CLASSIFIER_SEARCH_START: {
        draft.loading = true;
        break;
      }

      case CLASSIFIER_SEARCH_SUCCESS: {
        draft.search.results = action.payload;
        draft.loading = false;
        break;
      }

      case CLASSIFIER_SEARCH_FAILURE: {
        draft.loading = false;
        break;
      }

      case CATEGORY_LIST_GET_FAILURE: {
        draft.categories.data = [];
        draft.categories.loading = false;
        break;
      }
      case CATEGORY_LIST_GET_START: {
        draft.categories.data = [];
        draft.categories.loading = true;
        break;
      }
      case CATEGORY_LIST_GET_SUCCESS: {
        draft.categories.data = action.payload;
        draft.categories.loading = false;
        break;
      }

      case CATEGORY_UPDATE_START:
      case CATEGORY_CREATE_START: {
        draft.categories.loading = true;
        break;
      }

      case CATEGORY_UPDATE_END:
      case CATEGORY_CREATE_END: {
        draft.categories.loading = false;
        break;
      }

      // skip default
    }
  });

export default classifierReducer;
