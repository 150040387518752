import React, { Component, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import AuthProvider from "./AuthProvider";
import ReduxToastr from "react-redux-toastr";
import LoginLayout from "./components/Layouts/LoginLayout";
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";
import Loader from "./components/Loader/Loader";
import SystemRoute from "./components/SystemRoute";

const NotFound = lazy(() => import("./pages/ErrorPages/404"));
const Forbidden = lazy(() => import("./pages/ErrorPages/403"));
const UserList = lazy(() => import("./pages/Administration/User/UserList"));
const UserCreateForm = lazy(() =>
  import("./pages/Administration/User/UserCreateForm"),
);
const UserUpdateForm = lazy(() =>
  import("./pages/Administration/User/UserUpdateForm"),
);
const OrganizationList = lazy(() =>
  import("./pages/Administration/Organization/OrganizationList"),
);
const OrganizationCreate = lazy(() =>
  import("./pages/Administration/Organization/OrganizationCreate"),
);
const OrganizationItem = lazy(() =>
  import("./pages/Administration/Organization/OrganizationItem"),
);
const PlatformSettings = lazy(() =>
  import("./pages/Administration/PlatformSettings"),
);
const ControlPanel = lazy(() => import("./pages/ControlPanel"));
const SupplierOrganizationContracts = lazy(() =>
  import("./pages/Supplier/SupplierOrganizationContracts"),
);
const SupplierContracts = lazy(() =>
  import("./pages/Supplier/SupplierContracts"),
);
const PartnerOrganizationContracts = lazy(() =>
  import("./pages/Partner/PartnerOrganizationContracts"),
);
const PartnerContracts = lazy(() => import("./pages/Partner/PartnerContracts"));
const PartnerVolumeDiscounts = lazy(() =>
  import("./pages/Partner/PartnerVolumeDiscounts"),
);
const PartnerTotalVolumeDiscounts = lazy(() =>
  import("./pages/Partner/PartnerTotalVolumeDiscounts"),
);
const LoginPage = lazy(() => import("./pages/LoginPage"));
const Home = lazy(() => import("./pages/HomePage"));
const Suppliers = lazy(() => import("./pages/Supplier/SupplierPage"));
const Partners = lazy(() => import("./pages/Partner/PartnerPage"));
const PartnerCard = lazy(() => import("./pages/Partner/PartnerCard"));
const SupplierCard = lazy(() => import("./pages/Supplier/SupplierCard"));
const Forgot = lazy(() => import("./pages/Forgot"));
const Register = lazy(() => import("./pages/Register"));
const TurnoverSubmit = lazy(() => import("./pages/Turnover/TurnoverSubmit"));
const TurnoverSummary = lazy(() => import("./pages/Turnover/TurnoverSummary"));
const UserSettings = lazy(() => import("./pages/UserSettings"));
const SupplierReports = lazy(() => import("./pages/Reports/SupplierReports"));
const PartnerReports = lazy(() => import("./pages/Reports/PartnerReports"));
const SupplierPartnerReports = lazy(() =>
  import("./pages/Reports/SupplierPartnerReports"),
);
const LossReports = lazy(() => import("./pages/Reports/LossReports"));
const SearchResults = lazy(() => import("./pages/SearchResults"));

class App extends Component {
  componentDidCatch(error, errorInfo) {
    // window.location.reload();
  }

  static getDerivedStateFromError(error, message) {
    return null;
  }

  render() {
    const Fallback = () => {
      return (
        <div className={"loader-full"}>
          <Loader active={true} />
        </div>
      );
    };
    return (
      <Suspense fallback={<Fallback />}>
        <AuthProvider>
          <Switch>
            <SystemRoute
              exact
              path="/login"
              isPrivate={false}
              layout={LoginLayout}
              component={LoginPage}
            />
            <SystemRoute
              exact
              path="/forgot"
              isPrivate={false}
              layout={LoginLayout}
              component={Forgot}
            />
            <SystemRoute
              exact
              path="/reset/:id"
              isPrivate={false}
              layout={LoginLayout}
              component={Register}
            />
            <SystemRoute
              exact
              path="/forgot"
              layout={LoginLayout}
              component={Forgot}
            />
            <SystemRoute
              path="/contracts/partner"
              component={PartnerContracts}
            />
            <SystemRoute path="/search" component={SearchResults} />
            <SystemRoute
              path="/contracts/supplier"
              component={SupplierContracts}
            />
            <SystemRoute
              path="/partner/contracts/:id"
              component={PartnerOrganizationContracts}
            />
            <SystemRoute
              path="/discounts/partner"
              component={PartnerTotalVolumeDiscounts}
            />
            <SystemRoute
              path="/partner/discounts/:id"
              component={PartnerVolumeDiscounts}
            />
            <SystemRoute path="/partner/:id" component={PartnerCard} />
            <SystemRoute
              path="/supplier/contracts/:id"
              component={SupplierOrganizationContracts}
            />
            <SystemRoute path="/supplier/:id" component={SupplierCard} />
            <SystemRoute exact path="/partner" component={Partners} />
            <SystemRoute exact path="/supplier" component={Suppliers} />
            <SystemRoute
              exact
              path="/turnover/submit"
              component={TurnoverSubmit}
            />
            <SystemRoute
              exact
              path="/turnover/submit/:id"
              component={TurnoverSubmit}
            />
            <SystemRoute
              exact
              path="/turnover/summary"
              component={TurnoverSummary}
            />
            <SystemRoute exact path="/admin/users" component={UserList} />
            <SystemRoute
              exact
              path="/admin/users/create"
              component={UserCreateForm}
            />
            <SystemRoute
              exact
              path="/admin/users/:id"
              component={UserUpdateForm}
            />
            <SystemRoute
              exact
              path="/admin/organizations"
              component={OrganizationList}
            />
            <SystemRoute
              exact
              path="/admin/organizations/create"
              component={OrganizationCreate}
            />
            <SystemRoute
              exact
              path="/admin/organizations/:id"
              component={OrganizationItem}
            />
            <SystemRoute
              exact
              path="/admin/settings"
              component={PlatformSettings}
            />
            <SystemRoute exact path="/panel" component={ControlPanel} />
            <SystemRoute exact path="/settings" component={UserSettings} />
            <SystemRoute
              exact
              path="/reports/partner"
              component={PartnerReports}
            />
            <SystemRoute
              exact
              path="/reports/supplier"
              component={SupplierReports}
            />
            <SystemRoute
              exact
              path="/reports/supplierPartner"
              component={SupplierPartnerReports}
            />
            <SystemRoute exact path="/reports/losses" component={LossReports} />
            <SystemRoute exact path="/" component={Home} />
            <SystemRoute exact path="/forbidden" component={Forbidden} />
            <SystemRoute exact path="/404" component={NotFound} />
            <SystemRoute path="/*" component={NotFound} />
          </Switch>
          <ReduxToastr />
        </AuthProvider>
      </Suspense>
    );
  }
}

export default App;
