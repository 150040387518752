import { Component } from "react";
import { connect } from "react-redux";

import { verifyAuth } from "./store/actions/authActions";

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
});

const mapDispatchToProps = dispatch => ({
  verifyAuth: () => dispatch(verifyAuth()),
});

/**
 * Component which will possibly include loaders/validation.
 * Also useful since <AuthProvider> component does not support multiple elements
 */
class AuthProvider extends Component {
  componentDidMount() {
    this.props.verifyAuth();
  }

  render() {
    return this.props.children;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthProvider);
