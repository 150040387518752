import "./polyfill";
import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { createBrowserHistory } from "history";
import { IntlProvider } from "react-intl-redux";
import { addLocaleData } from "react-intl";
import lvLocaleData from "react-intl/locale-data/lv";
import localeData from "./assets/messages/index";
import moment from "moment";
import { ConnectedRouter } from "connected-react-router";
import Socket from "./store/socket";
// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';

const history = createBrowserHistory();
const initialState = {
  intl: {
    locale: "lv",
    messages: localeData.lv,
  },
};

const socket = new Socket();
const store = configureStore(initialState, history, socket);

addLocaleData([...lvLocaleData]);
render(
  <Provider store={store}>
    <IntlProvider
      key={initialState.locale}
      locale={initialState.locale}
      messages={initialState.messages}
    >
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById(`root`) || document.createElement(`root`),
  // for unit testing purposes,
);
// disable ServiceWorker
// if (config.environment === "production") registerServiceWorker();

export { store, history };

// Prototype overrides
Date.prototype.toJSON = function() {
  return moment(this).format("L");
};
