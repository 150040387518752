import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";

export default class ErrorPage extends Component {
  static propTypes = {
    title: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  };

  render() {
    const { title, text } = this.props;

    return (
      <div className="flex-row align-items-center error-page">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <h1 className="display-3 d-flex justify-content-center">
                        {title}
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="text-muted d-flex justify-content-center">
                        {text}
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
