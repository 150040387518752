import {
  SUPPLIER_LIST_GET_START,
  SUPPLIER_LIST_GET_SUCCESS,
  SUPPLIER_LIST_GET_FAILURE,
  SUPPLIER_CONTRACT_LIST_GET_START,
  SUPPLIER_CONTRACT_LIST_GET_SUCCESS,
  SUPPLIER_CONTRACT_LIST_GET_FAILURE,
  SUPPLIER_GET_START,
  SUPPLIER_GET_SUCCESS,
  SUPPLIER_GET_FAILURE,
  SUPPLIER_CONTRACT_DELETE_FAILURE,
  SUPPLIER_CONTRACT_DELETE_SUCCESS,
  SUPPLIER_CONTRACT_DELETE_START,
  SUPPLIER_CONTRACT_LIST_UPLOAD_START,
  SUPPLIER_CONTRACT_LIST_UPLOAD_END,
  SUPPLIER_CONTRACT_LIST_RESET,
} from "../constants/actionTypes";
import produce from "immer";

const defaultState = {
  list: {
    data: [],
    page: 1,
    count: 0,
  },
  contracts: {
    list: {
      data: [],
      page: 1,
      count: 0,
    },
  },
  item: {
    name: "",
    registrationNr: "",
    address: "",
  },
  loading: false,
};

/* eslint-disable default-case */
/**
 * Redux reducer for suppliers
 * @param state
 * @param action
 */
const supplierReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { payload, type } = action;
    switch (type) {
      case SUPPLIER_GET_SUCCESS: {
        draft.item = { ...payload };
        draft.loading = false;
        break;
      }

      case SUPPLIER_GET_FAILURE: {
        draft.name = "";
        draft.registrationNr = "";
        draft.address = "";
        draft.loading = false;
        break;
      }

      case SUPPLIER_GET_START:
      case SUPPLIER_LIST_GET_START:
      case SUPPLIER_CONTRACT_LIST_UPLOAD_START:
      case SUPPLIER_CONTRACT_DELETE_START: {
        draft.loading = true;
        break;
      }

      case SUPPLIER_CONTRACT_LIST_GET_START: {
        draft.loading = true;
        draft.contracts.lodaing = true;
        break;
      }

      case SUPPLIER_LIST_GET_SUCCESS: {
        draft.list.data = payload.data.rows;
        draft.list.count = payload.data.count;
        draft.list.page = payload.page;
        draft.loading = false;
        break;
      }

      case SUPPLIER_LIST_GET_FAILURE: {
        draft.list.data = [];
        draft.list.count = 0;
        draft.loading = false;
        break;
      }

      case SUPPLIER_CONTRACT_LIST_GET_SUCCESS: {
        draft.contracts.list = {
          data: payload.data.rows,
          page: payload.data.count,
          count: payload.page,
        };
        draft.loading = false;
        draft.contracts.loading = false;
        break;
      }

      case SUPPLIER_CONTRACT_LIST_GET_FAILURE: {
        draft.contracts.list = {
          data: [],
          page: 1,
          count: 0,
        };
        draft.loading = false;
        draft.contracts.loading = false;
        break;
      }

      case SUPPLIER_CONTRACT_LIST_RESET: {
        draft.contracts = defaultState.contracts;
        break;
      }

      case SUPPLIER_CONTRACT_DELETE_SUCCESS:
      case SUPPLIER_CONTRACT_DELETE_FAILURE:
      case SUPPLIER_CONTRACT_LIST_UPLOAD_END: {
        draft.loading = false;
        break;
      }

      // skip default
    }
  });

export default supplierReducer;
