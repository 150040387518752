import produce from "immer";
import {
  PLATFORM_SETTINGS_GET_FAILURE,
  PLATFORM_SETTINGS_GET_START,
  PLATFORM_SETTINGS_GET_SUCCESS,
  PLATFORM_SETTINGS_UPDATE_END,
  PLATFORM_SETTINGS_UPDATE_START,
  GLOBAL_LOADING_PROGRESS,
  GLOBAL_LOADING_FINISH,
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_RESET,
} from "../constants/actionTypes";

const defaultState = {
  history: [],
  historyIdx: [],
  idx: 0,
  platformSettings: {
    loading: false,
    data: [],
  },
  loading: false,
  upload: {
    loading: false,
    id: null,
  },
};

/* eslint-disable default-case */
/**
 * Redux reducer for routes
 * @param state
 * @param action
 */
const globalReducer = (state = defaultState, action) =>
  produce(state, draft => {
    const { type } = action;
    switch (type) {
      case "@@router/LOCATION_CHANGE": {
        if (action.payload.action === "PUSH") {
          if (draft.idx !== draft.history.length) {
            draft.history = draft.history.slice(0, draft.idx);
          }

          draft.history = [...draft.history, action.payload.location.pathname];
          draft.idx = draft.history.length;
        }
        break;
      }
      case "BACK_BUTTON_CLICK": {
        draft.idx -= 1;

        break;
      }
      case "FORWARD_BUTTON_CLICK": {
        draft.idx += 1;

        break;
      }

      case PLATFORM_SETTINGS_GET_START: {
        draft.platformSettings = {
          loading: true,
          data: [],
        };
        break;
      }
      case PLATFORM_SETTINGS_GET_SUCCESS: {
        draft.platformSettings = {
          loading: false,
          data: action.payload,
        };
        break;
      }
      case PLATFORM_SETTINGS_GET_FAILURE:
      case PLATFORM_SETTINGS_UPDATE_END: {
        draft.platformSettings.loading = false;
        break;
      }
      case GLOBAL_LOADING_FINISH: {
        draft.loading = false;
        break;
      }

      case GLOBAL_LOADING_PROGRESS: {
        draft.loading = true;
        break;
      }

      case PLATFORM_SETTINGS_UPDATE_START: {
        draft.platformSettings.loading = true;
        break;
      }

      case FILE_UPLOAD_PROGRESS: {
        draft.upload = {
          loading: true,
          id: null,
        };
        break;
      }

      case FILE_UPLOAD_SUCCESS: {
        draft.upload = {
          loading: false,
          id: action.payload.id,
        };
        break;
      }

      case FILE_UPLOAD_RESET:
      case FILE_UPLOAD_FAILURE: {
        draft.upload = {
          loading: false,
          id: null,
        };
        break;
      }

      // skip default
    }
  });

export default globalReducer;
