import SystemRoute from "./SystemRoute";
import { connect } from "react-redux";
import {
  redirectToDefault,
  redirectToLogin,
  redirectTo403,
  redirectTo404,
} from "../../store/actions/routingActions";

const mapStateToProps = state => ({
  auth: state.auth,
  router: state.router,
  users: state.users,
  loading: state.global.loading || state.export.loading,
  renderPage: state.auth.renderPage,
});

const mapDispatchToProps = dispatch => ({
  redirectToLogin: () => dispatch(redirectToLogin()),
  redirectToDefault: () => dispatch(redirectToDefault()),
  redirectTo403: () => dispatch(redirectTo403()),
  redirectTo404: () => dispatch(redirectTo404()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemRoute);
